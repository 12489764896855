import { Profile, RouteInfo } from '../../shared/models';

export function CUSTOMERS_ROUTES(profile?: Profile): RouteInfo[] {
    return [
        {
            path: '/panel/customers/all', title: 'All', icon: 'fa fa-list-ul', class: '', badge: '',
            badgeClass: '', isExternalLink: false, submenu: [], permission: 'customer_view_all'
        },
        // {
        //     path: '/panel/customers/recent', title: 'All', icon: 'fa fa-list-ul', class: '', badge: '',
        //     badgeClass: '', isExternalLink: false, submenu: [], permission: 'customer_view_all'
        // },
        // {
        //     path: '/panel/customers/register', title: 'Registration', icon: '', class: '', badge: '',
        //     badgeClass: '', isExternalLink: false, submenu: [], permission: 'customer_register'
        // },
        // {
        //     path: '/panel/customers/statements', title: 'Statements', icon: 'fa fa-bars', class: '', badge: '',
        //     badgeClass: '', isExternalLink: false, submenu: [], permission: 'customer_view_statement'
        // },
        {
            path: '/panel/customers/client-statements', title: 'Statements', icon: 'fa fa-bars', class: '', badge: '',
            badgeClass: '', isExternalLink: false, submenu: [], permission: 'customer_view_statement'
        },
        {
         path: '/panel/customers/referral', title: 'Referral', icon: 'fas fa-chart-line', class: '', badge: '', badgeClass: '',
            isExternalLink: false, submenu: [], permission: 'customer_view_invite_graph'
        },
        {
         path: '/panel/customers/wallet', title: 'Wallet', icon: 'fas fa-save', class: '', badge: '', badgeClass: '',
            isExternalLink: false, submenu: [], permission: 'wallet_view_all'
        },
        // {
        //     path: '/panel/customers/credit-scores', title: 'Scores', icon: '', class: '', badge: '', badgeClass: '',
        //     isExternalLink: false, submenu: [], permission: 'customer_view_credit_scores'
        // },
    ];
}
