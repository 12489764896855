import {
  Injectable,
  Component,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef,
} from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Title, DomSanitizer } from "@angular/platform-browser";
import { Observable, Subject } from "rxjs";

import { environment } from "../../environments/environment";
import { DataTableDirective } from "angular-datatables";
import { UploadEvent, ButtonConfig, DataTablesResponse } from "./models";
import { AuthService } from "./auth/auth.service";

import * as moment from "moment";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { ERRORS_EN } from "./error-codes";
import { SwalService } from "./services/swal.service";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class SharedService {
  private server_url = environment.serverUrl;
  // Subject for Page focus change
  private pageFocusChangeSource: Subject<boolean> = new Subject();

  public pageFocusChanged = this.pageFocusChangeSource.asObservable();

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private title: Title,
    private injector: Injector,
    private authService: AuthService,
    private swalService: SwalService,
    private toast: ToastrService
  ) {
    this.observePageChanges();
  }

  /**
   * The POST requests wrapper to send any request to the server.
   * @param endpoint The endpoint we are calling at core.
   * @param data The data we are sending to core.
   */
  public postWrapper<T>(
    endpoint: string,
    data?: any
  ): Observable<HttpResponse<T>> {
    if (data) {
      data["target"] = endpoint;
    } else {
      data = { target: endpoint };
    }
    return this.http.post<T>(`${this.server_url}api/${endpoint}`, data, {
      observe: "response",
    });
  }

  /**
   * Sets the currentn title for the page using the set convention.
   * @param title The title we are setting for the current page.
   */
  public setPageTitle(title: string) {
    this.title.setTitle(title + " | " + this.ROOT_TITLE);
  }

  /**
   * Renders the date and time as DD/MM/YYYY, h:m:s A
   * @param date The datetime we want to format
   * @param type The type of request for the row data
   */
  renderLocalDateTime(date: any, type: any) {
    if (type === "list" || type === "display") {
      try {
        if (moment(date).isValid()) {
          return moment(date).format("DD/MM/YYYY, hh:mm:ss A");
        }
      } catch (error) {
        return new Date(date).toLocaleString();
      }
    }
    return date;
  }

  /**
   * Renders the buttons extension accordingly.
   * @param buttonsConfig Buttons configuration to use for this set up
   */
  renderDefaultGridButtons(buttonsConfig: ButtonConfig): Array<any> {
    if (!buttonsConfig) {
      return [];
    }
    if (!buttonsConfig.title) {
      buttonsConfig.title = this.title.getTitle();
    }
    buttonsConfig.title +=
      "_" + moment(new Date()).format("YYYY_MM_DD_HH_mm_ss");
    let buttons = [];
    if (!buttonsConfig.excludeLastColumn) {
      buttons = [
        // {
        //     extend: 'copyHtml5',
        //     titleAttr: 'Copy to Clipboard',
        //     title: buttonsConfig.title,
        //     messageBottom: () => 'Generated at ' + this.renderLocalDateTime(new Date(), 'list'),
        //     exportOptions: {
        //         rows: ':visible'
        //     }
        // },
        // {
        //     extend: 'print',
        //     titleAttr: 'Print from Browser',
        //     title: buttonsConfig.title,
        //     messageBottom: () => 'Generated at ' + this.renderLocalDateTime(new Date(), 'list'),
        //     exportOptions: {
        //         rows: ':visible'
        //     }
        // },
        // {
        //     extend: 'csvHtml5',
        //     titleAttr: 'Export as CSV',
        //     title: buttonsConfig.title,
        //     exportOptions: {
        //         rows: ':visible'
        //     }
        // },
        {
          extend: "excelHtml5",
          titleAttr: "Export as Excel Sheet",
          title: buttonsConfig.title,
          exportOptions: {
            rows: ":visible",
          },
        },
        {
          extend: "pdfHtml5",
          titleAttr: "Export as PDF",
          title: buttonsConfig.title,
          messageBottom: () =>
            "Generated at " + this.renderLocalDateTime(new Date(), "list"),
          orientation: buttonsConfig.pdfOrientation,
          exportOptions: {
            rows: ":visible",
          },
        },
      ];
    } else {
      buttons = [
        // {
        //     extend: 'copyHtml5',
        //     titleAttr: 'Copy to Clipboard',
        //     title: buttonsConfig.title,
        //     exportOptions: {
        //         columns: ':not(:last-child)',
        //     },
        //     messageBottom: () => 'Generated at ' + this.renderLocalDateTime(new Date(), 'list')
        // },
        // {
        //     extend: 'print',
        //     titleAttr: 'Print from Browser',
        //     title: buttonsConfig.title,
        //     exportOptions: {
        //         columns: ':not(:last-child)',
        //     },
        //     messageBottom: () => 'Generated at ' + this.renderLocalDateTime(new Date(), 'list')
        // },
        // {
        //     extend: 'csvHtml5',
        //     titleAttr: 'Export as CSV',
        //     title: buttonsConfig.title,
        //     exportOptions: {
        //         columns: ':not(:last-child)',
        //     }
        // },
        {
          extend: "excelHtml5",
          titleAttr: "Export as Excel Sheet",
          title: buttonsConfig.title,
          exportOptions: {
            columns: ":not(:last-child)",
          },
        },
        {
          extend: "pdfHtml5",
          titleAttr: "Export as PDF",
          title: buttonsConfig.title,
          exportOptions: {
            columns: ":not(:last-child)",
          },
          messageBottom: () =>
            "Generated at " + this.renderLocalDateTime(new Date(), "list"),
          orientation: buttonsConfig.pdfOrientation,
        },
      ];
    }

    if (buttonsConfig.children) {
      buttons = [...buttons, buttonsConfig.children];
    }
    return buttons;
  }

  /**
   * Retrieves the datatables options accordingly as set by supplying the Ajax callback
   * function and the columns to be rendered.
   *
   * @param columns The list of column definition to be rendered.
   * @param ajaxCallback The ajax callback function that receives dataTablesParameters and the callback to be
   * called to process the response.
   * @param columnDefs: The column definition for customizing the behaviour per column targeted.
   * @param ordering The column or columns to use for ordering by default. If type is number, then we assume
   * is the column index, otherwise, assume it's already a definition of the items to order by.
   * @param rowCallback The callback that's executed after rendering each column in the data.
   * @param retrieve This allows instructing DataTables to retrieve an instance when it's loaded instead of
   * attempting to create another instance.
   * @param destroy This instructs DataTables to destroy and recreate the dt instance when we re-initialize
   * it again instead of trying to instantiate directly a DT instance.
   * @param buttonsConfig The buttons config we are using to define how we render the export buttons.
   * @param rowReorder Whether to reorder the columns or not. By default the last column is excluded as
   * that may contain actions and we don't want to mess with those.
   * @param searchDelay The search delay (in milliseconds) we use for sending DT queries.
   * @param paging Whether to allow for pagingatin or not.
   * @param searching Whether to allow for searching or not.
   * @param showInfo Whether to allow for show Info or not.
   * @description @see {retrieve & destroy} are mutually exclusive. Retrieve will always take precedence
   * thereof. For destroy to be effected, set retrieve to false.
   * @returns The datatables settings for options.
   */
  public getDataTablesOptions(
    columns: any[],
    ajaxCallback: Function,
    columnDefs = [],
    ordering: number | any[] = 0,
    rowCallback: Function = null,
    retrieve = true,
    destroy = false,
    buttonsConfig: ButtonConfig = new ButtonConfig(),
    rowReorder = false,
    searchDelay = 400,
    paging = true,
    searching = true,
    showInfo = true
  ) {
    if (retrieve && destroy) {
      destroy = false;
    }
    let reordering = null;
    if (rowReorder) {
      reordering = {
        selector: "td:not(:last-child)",
      };
    } else {
      reordering = undefined;
    }

    let order: any;
    if (typeof ordering === "number") {
      order = [[ordering, "desc"]];
    } else {
      order = ordering;
    }
    return {
      pagingType: "numbers",
      pageLength: 10,
      serverSide: true,
      processing: true,
      info: showInfo,
      lengthChange: true,
      paging: paging,
      searching: searching,
      retrieve: retrieve,
      destroy: destroy,
      language: {
        processing: this.DT_LOADER,
        emptyTable: "No data available in table",
      },
      autoWidth: true,
      scrollX: true,
      ajax: (dataTablesParameters: any, callback) => {
        try {
          return ajaxCallback(dataTablesParameters, callback);
        } catch (error) {}
      },
      columns: columns,
      columnDefs: columnDefs,
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        if (rowCallback !== null && rowCallback !== undefined) {
          return rowCallback(row, data, index);
        }
      },
      order: order,
      dom: "lBfrtip",
      buttons: this.renderDefaultGridButtons(buttonsConfig),
      rowReorder: reordering,
      searchDelay: searchDelay,
    };
  }

  /**
   * Retrieves the config for client side DTs
   * @param columnDefs The column definitions, if any, of how to render the columns.
   * @param ordering The default ordering for the columns, if any.
   * @param rowCallback The row callback for the definitions of the column actions, etc.
   * @param buttonsConfig The button config, if different from the default ones.
   * @param rowReorder The reordering definition.
   * @param paging Whether to enable pagination or not.
   * @param searching Whether to allow searching or not.
   * @param showInfo Whether to show the pagination info or not.
   * @param autoWidth Whether to automatically adjust the width of the table.
   * @param scrollX Whether to scroll horizontally when columns overlap
   */
  public getClientSideDataTablesOptions(
    columnDefs = [],
    ordering: number | any[] = 0,
    rowCallback: Function = null,
    buttonsConfig: ButtonConfig = new ButtonConfig(),
    rowReorder = false,
    paging = true,
    searching = true,
    showInfo = true,
    autoWidth = true,
    scrollX = true
  ) {
    let reordering = null;
    if (rowReorder) {
      reordering = {
        selector: "td:not(:last-child)",
      };
    } else {
      reordering = undefined;
    }

    let order: any;
    if (typeof ordering === "number") {
      order = [[ordering, "desc"]];
    } else if (ordering) {
      order = ordering;
    }
    return {
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: false,
      processing: true,
      info: showInfo,
      paging: paging,
      searching: searching,
      language: {
        processing: this.DT_LOADER,
        emptyTable: "No data available in table",
      },
      autoWidth: autoWidth,
      scrollX: scrollX,
      columnDefs: columnDefs,
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        if (rowCallback !== null && rowCallback !== undefined) {
          return rowCallback(row, data, index);
        }
      },
      order: order,
      dom: "lBfrtip",
      buttons: this.renderDefaultGridButtons(buttonsConfig),
      rowReorder: reordering,
    };
  }

  /**
   * Re-renders the DT by destroying it and recreating it with the set options.
   * @param dtElement The DataTableDirective fetched from the HTML datatable directive
   * @param dtTrigger The DTTrigger attached to the table we are rendering.
   */
  public rerenderDT(
    dtElement: DataTableDirective,
    dtTrigger: Subject<any>
  ): void {
    if (typeof dtElement === "undefined" || typeof dtTrigger === "undefined") {
      return;
    }
    if (typeof dtElement.dtInstance === "undefined") {
      return;
    }
    dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      dtTrigger.next();
    });
  }

  public processDTResponse(
    response: HttpResponse<DataTablesResponse>,
    callback: Function
  ) {
    if (response.ok) {
      const responseBody = response.body;
      if (responseBody.draw) {
        return callback({
          recordsTotal: responseBody.recordsTotal,
          recordsFiltered: responseBody.recordsFiltered,
          data: responseBody.data,
        });
      }
    }
    return callback({
      recordsTotal: 0,
      recordsFiltered: 0,
      data: [],
    });
  }

  /**
   * Returns the Root title that can be appended to paths
   */
  public get ROOT_TITLE(): string {
    return "Zerahi 360";
  }

  /**
   * Gets the DT loader divs for animated div
   */
  public get DT_LOADER(): string {
    return (
      "<div></div><div></div><div></div><div></div><div></div>" +
      "<div></div><div></div><div></div><div></div><div></div><div></div><div></div>"
    );
  }

  /**
   * Handle updating our observable of a change in the page focus.
   * This helps us determine whether we can run some queries or not based on whether our pages
   * are currently being viewed or not.
   */
  private focusChanged(focus: boolean) {
    this.pageFocusChangeSource.next(focus);
  }

  /**
   * Monitors the page focus changes to broadcast respective events to be observed.
   */
  private observePageChanges() {
    const that = this;
    let visibilityChange: any;
    if (typeof document.hidden !== "undefined") {
      visibilityChange = "visibilitychange";
    } else if (typeof document.mozHidden !== "undefined") {
      visibilityChange = "mozvisibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      visibilityChange = "webkitvisibilitychange";
    }

    document.addEventListener(visibilityChange, function () {
      that.focusChanged(document.hasFocus());
    });
    window.addEventListener("focus", function () {
      that.focusChanged(document.hasFocus());
    });
    window.addEventListener("blur", function () {
      that.focusChanged(document.hasFocus());
    });
  }

  appendComponentToBody(component: any) {
    // 1. Create a component reference from the component
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    return domElem;
    // 4. Append DOM element to the body
    // document.body.appendChild(domElem);
    // console.log(domElem);
    // return domElem;
  }

  /**
   * getDTActionsMenu
   * Retrieves the actions button for the DT rows.
   */
  public getDTActionsMenu() {
    return this.appendComponentToBody(
      this.createDynamicComponent(`
    <div ngbDropdown>
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Actions</button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="dropdown-item">Action</button>
            <button class="dropdown-item">Another Action</button>
            <button class="dropdown-item">Something else is here</button>
        </div>
    </div>`)
    );
  }

  private createDynamicComponent(template: string) {
    @Component({
      selector: "ox-custom-dynamic-component",
      template: template,
    })
    class CustomDynamicComponent {}
    return CustomDynamicComponent;
  }

  /**
   * Attempts to format the provided date into the ngbDate format so that it can be used in a date picker.
   * @param dateToFormat The date we want to format.
   * @returns @see NgbDate date form.
   */
  formatNgbDate(dateToFormat?: string | Date): NgbDate {
    if (dateToFormat) {
      const momentizedDate = moment(dateToFormat);
      return new NgbDate(
        momentizedDate.year(),
        momentizedDate.month() + 1,
        momentizedDate.date()
      );
    }
    return null;
  }

  /**
   * Makes an attempt to subtract the date start from date end
   * @param dateEnd The date to subtract from.
   * @param dateStart The date to subtract.
   * @returns the difference number
   */
  subtractDates(dateEnd: string | Date, dateStart: string | Date): number {
    if (dateEnd && dateStart) {
      const momentizedStartDate = moment(dateStart);
      const momentizedEndDate = moment(dateEnd);
      return momentizedEndDate.diff(momentizedStartDate, "days") + 1;
    }
    return null;
  }

  /**
   * Handles Errors - Displaying the respective error message based on the code provided.
   * @param code The error code received from the server.
   * @param showModal Whether to display the swal or tost for this error. Defaults `true`.
   */
  public handleError(code: string, showModal = true): void {
    const errorModel = ERRORS_EN.find((v, _i, _objs) => v.code === code);
    let errorMessage = "Processing your request failed. Try again later!";
    if (errorModel && errorModel.message) {
      errorMessage = errorModel.message;
    }
    if (showModal) {
      this.swalService.error("Processing Error", errorMessage);
    } else {
      this.toast.error(errorMessage, "Processing Error");
    }
  }

  /**
   * The POST requests wrapper to send download request to the server.
   * @param endpoint The endpoint we are calling at core.
   * @param data The data we are sending to core.
   */
  public downloadWrapper(
    endpoint: string,
    data?: any
  ): Promise<HttpResponse<Blob>> {
    if (data) {
      data["target"] = endpoint;
    } else {
      data = { target: endpoint };
    }
    return this.http
      .post<Blob>(`${this.server_url}api/${endpoint}`, data, {
        observe: "response",
        responseType: "blob" as "json",
      })
      .toPromise();
  }

  /**
   * Uploads a file to the given endpoint.
   * @param endpoint The core endpoint we are sending the data to.
   * @param formData The form data we are sending to the server.
   */
  public uploadFile(
    endpoint: string,
    formData: FormData
  ): Observable<UploadEvent> {
    try {
      const progressReport = new Subject<UploadEvent>();
      const oReq = new XMLHttpRequest();
      oReq.open("POST", `${this.server_url}api/${endpoint}`, true);
      oReq.onprogress = (pEVent: ProgressEvent) =>
        progressReport.next({ event: pEVent, source: oReq, complete: false });
      oReq.onload = (oEvent: ProgressEvent) => {
        progressReport.next({ event: oEvent, source: oReq, complete: true });
        progressReport.complete();
      };
      oReq.onerror = (oEvent: any) => progressReport.error(oEvent);
      // Append the necessary data
      formData.append("token", this.authService.getToken());
      formData.append("client_id", environment.clientId);
      formData.append("target", endpoint);

      oReq.send(formData);
      return progressReport.asObservable();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Auto-magically download a file returned as HttpResponse from an API request.
   * @param response The HttpResponse containing a blob item to be rendered.
   */
  downloadFile(response: HttpResponse<Blob>) {
    let filename: any;
    try {
      const filenameAttachment = response.headers
        .get("content-disposition")
        .split(";")[1];
      if (filenameAttachment ) {
        filename = filenameAttachment
        .replace('filename = "', "")
        .replace('"', "");
      } else {
        filename = "report.xls";
        console.log(filename)
      }
      
    } catch (error) {
      // TODO: Just read the content type and try to figure out what it might be from a collection of knowns!
      filename = "report.xls";
    }
    const url = window.URL.createObjectURL(response.body);
    const downloadLink = document.createElement("a");
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.setAttribute("href", url);
    downloadLink.setAttribute("download", filename);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
  }

  /**
   * Allows generation of a preview link that can be used in images and content downloads.
   * @param response The HttpResponse with a blob content that we want to generated a safe URL for it.
   * @returns The trusted URL that can be used in things like img tags.
   */
  previewFile(response: HttpResponse<Blob>) {
    let fileType: any;
    try {
      fileType = response.headers.get("content-type");
    } catch (error) {
      // TODO: Just read the content type and try to figure out what it might be from a collection of knowns!
      fileType = "text/plain";
    }
    return this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(new Blob([response.body], { type: fileType }))
    );
  }
  previewPDFFile(response: HttpResponse<Blob>) {
    let fileType: any;
    try {
      fileType = response.headers.get("content-type");
    } catch (error) {
      // TODO: Just read the content type and try to figure out what it might be from a collection of knowns!
      fileType = "text/plain";
    }
    return window.URL.createObjectURL(
      new Blob([response.body], { type: fileType })
    );
  }
}