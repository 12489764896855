import { Component } from '@angular/core';

@Component({
    selector: 'ox-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent{
    //Variables
    currentDate : Date = new Date();
}
