import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private toast: ToastrService) { }

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      if (route.data['permission']) {
        const hasPerm = this.authService.hasPermission(route.data['permission']);
        if (!hasPerm) {
          this.toast.warning(
            'You don\'t have permissions to access the request page. Contact your administrator for more details.',
            'Permission Denied');
        }
        return Promise.resolve<boolean>(hasPerm);
      } else {
        return Promise.resolve<boolean>(true);
      }
    }
    return Promise.resolve<boolean>(false);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }
}
