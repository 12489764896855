import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueOrQuestion'
})
export class ValueOrQuestionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value && value !== '') {
      return value;
    } else {
      return '?';
    }
  }

}
