import { Injectable } from '@angular/core';
import swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

@Injectable()
export class SwalService {

  constructor() { }

  /**
   * The default rendering of swal with some complicated settings.
   * Allows for complicated stuff to be rendered peacefully.
   * @param settings  @see SweetAlertOptions Sweet alert options for the modern sweet alerts.
   * @returns The result of the sweet alert dialog, a dismiss or accept literally.
   * @ typedef SweetAlertResult
   */
  public render(settings: SweetAlertOptions & { useRejections?: false }): Promise<SweetAlertResult> {
    return swal.fire(settings);
  }

  /**
   * Render an error swal.
   */
  public error(title: string, content: string): Promise<SweetAlertResult> {
    return swal.fire(title, content, 'error');
  }
  /**
   * Render an info swal.
   */
  public info(title: string, content: string): Promise<SweetAlertResult> {
    return swal.fire(title, content, 'info');
  }
  /**
   * Render a question swal.
   */
  public question(title: string, content: string): Promise<SweetAlertResult> {
    return swal.fire(title, content, 'question');
  }
  /**
   * Render a success swal.
   */
  public success(title: string, content: string): Promise<SweetAlertResult> {
    return swal.fire(title, content, 'success');
  }
  /**
   * Render a warning swal.
   */
  public warning(title: string, content: string): Promise<SweetAlertResult> {
    return swal.fire(title, content, 'warning');
  }

}
