import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
/**
   * Allows us to format the date time.
   * This makes this function re-usable when called directly.
   * @param value A date time string
   */
  static process(value: any): string {
    try {
      if (moment(value).isValid()) {
        return moment(value).format('DD/MM/YYYY hh:mm:ss A');
      }
    } catch (error) {
      return new Date(value).toLocaleString();
    }
  }

  static processNoHour(value: any): string {
    try {
      if (moment(value).isValid()) {
        return moment(value).format('DD/MM/YYYY');
      }
    } catch (error) {
      return new Date(value).toLocaleString();
    }
  }

  transform(value: any, args?: any): any {
    return DateTimeFormatPipe.process(value);
  }

}
