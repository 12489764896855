import { Component, OnInit, Input } from '@angular/core';
import { DashWidgetGroup } from './dash-widget.models';

@Component({
  selector: 'app-dash-widget-group',
  templateUrl: './dash-widget-group.component.html',
  styleUrls: ['./dash-widget-group.component.scss']
})
export class DashWidgetGroupComponent implements OnInit {
  /**
   * The model for this group
   */
  @Input() controller: DashWidgetGroup;
  constructor() { }

  ngOnInit() {
  }

}
