import {
  Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy,
  AfterViewInit, OnChanges, OnDestroy, ViewChild, ElementRef, Input,
  Output, EventEmitter, Renderer, SimpleChanges, forwardRef, Renderer2
} from '@angular/core';
import { Select2OptionData, Select2Options } from './select2.models';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
// tslint:disable-next-line: component-selector
  selector: 'celect2',
  templateUrl: './select2.component.html',
  styleUrls: ['./select2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Select2Component),
      multi: true
    }
  ]
})
export class Select2Component implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {
  /**
   * The current element we are attaching to.
   */
  private element: JQuery = undefined;
  private check = false;

  private style = `CSS`;

  @ViewChild('selector') selector: ElementRef;

  // data for select2 drop down
  @Input() data: Array<Select2OptionData>;

  // value for select2
  @Input() _selectedValue: string | string[] | number;

  // enable / disable default style for select2
  @Input() cssImport = false;

  // width of select2 input
  @Input() width: string;

  // enable / disable select2
  @Input() disabled = false;

  // all additional options
  @Input() options: Select2Options;

  // emitter when value is changed
  @Output() valueChanged = new EventEmitter();

  /**
   * The change propagator.
   */
  propagateChange = (_: any) => { };

  constructor(private renderer2: Renderer2) { }

  ngOnInit() {
    if (this.cssImport) {
      const head = document.getElementsByTagName('head')[0];
      const link: any = head.children[head.children.length - 1];

      if (!link.version) {
        // const newLink = this.renderer.createElement(head, 'style');
        // this.renderer.setElementProperty(newLink, 'type', 'text/css');
        // this.renderer.setElementProperty(newLink, 'version', 'select2');
        // this.renderer.setElementProperty(newLink, 'innerHTML', this.style);
        const newLink = this.renderer2.createElement('style');
        this.renderer2.setProperty(newLink, 'type', 'text/css');
        this.renderer2.setProperty(newLink, 'version', 'select2');
        this.renderer2.setProperty(newLink, 'innerHTML', this.style);
        head.appendChild(newLink);
      }

    }
  }

  /**
   * The data binding for the ngModel value.
   */
  @Input()
  public get value(): string | string[] | number {
    return this._selectedValue;
  }

  /**
   * Sets the data binding value and emits the value changed property.
   */
  public set value(v: string | string[] | number) {
    this._selectedValue = v;
    this.propagateChange(this._selectedValue);
    this.setElementValue(this._selectedValue);
  }


  async ngOnChanges(changes: SimpleChanges) {
    if (!this.element) {
      return;
    }

    if (changes['data'] && JSON.stringify(changes['data'].previousValue) !== JSON.stringify(changes['data'].currentValue)) {
      await this.initPlugin();

      const newValue = this.element.val();
      this.valueChanged.emit({
        value: newValue as string,
        data: this.element.select2('data')
      });
      this.value = newValue;
    }

    if (changes['value'] && changes['value'].previousValue !== changes['value'].currentValue) {
      const newValue = changes['value'].currentValue;

      this.setElementValue(newValue);

      this.valueChanged.emit({
        value: newValue,
        data: this.element.select2('data')
      });
      this.value = newValue;
    }

    if (changes['disabled'] && changes['disabled'].previousValue !== changes['disabled'].currentValue) {
      this.renderer2.setProperty(this.selector.nativeElement, 'disabled', this.disabled);
    }
  }

  async ngAfterViewInit() {
    this.element = jQuery(this.selector.nativeElement);
    await this.initPlugin();

    if (typeof this.value !== 'undefined') {
      this.setElementValue(this.value);
    } else if (typeof this.value !== 'undefined') {
      this.setElementValue(this.value);
    }

    this.element.on('select2:select select2:unselect', () => {
      this.valueChanged.emit({
        value: this.element.val(),
        data: this.element.select2('data')
      });
      this.value = this.element.val();
    });
  }

  ngOnDestroy() {
    if (this.element && this.element.off) {
      this.element.off('select2:select');
    }
  }

  private async initPlugin() {
    if (!this.element.select2) {
      if (!this.check) {
        this.check = true;
        console.log('Please add Select2 library (js file) to the project. You can download it from https://github.com/select2/select2/tree/master/dist/js.');
      }

      return;
    }

    // If select2 already initialized remove him and remove all tags inside
    if (this.element.hasClass('select2-hidden-accessible') == true) {
      this.element.select2('destroy');
      this.renderer2.setProperty(this.selector.nativeElement, 'innerHTML', '');
    }

    const options: Select2Options = {
      data: this.data,
      width: (this.width) ? this.width : 'resolve'
    };

    Object.assign(options, this.options);

    if (options.matcher) {
      const oldMatcher: any = await this.requireOldMatcher();
      options.matcher = oldMatcher(options.matcher);
      this.element.select2(options);

      if (typeof this.value !== 'undefined') {
        this.setElementValue(this.value);
      }
    } else {
      this.element.select2(options);
    }

    if (this.disabled) {
      this.renderer2.setProperty(this.selector.nativeElement, 'disabled', this.disabled);
    }
  }

  private async requireOldMatcher(): Promise<any> {
    return new Promise<any[]>(resolve => {
      jQuery.fn.select2.amd.require(['select2/compat/matcher'], (oldMatcher: any) => {
        resolve(oldMatcher);
      });
    });
  }

  private setElementValue(newValue: string | string[] | number) {
    if (Array.isArray(newValue)) {
      for (const option of this.selector.nativeElement.options) {
        if (newValue.indexOf(option.value) > -1) {
          this.renderer2.setProperty(option, 'selected', 'true');
        }
      }
    } else {
      this.renderer2.setProperty(this.selector.nativeElement, 'value', newValue);
    }

    if (typeof this.element === 'undefined') { return; }
    this.element.trigger('change.select2');
  }

  //#region Accessor implementations
  writeValue(obj: any): void {
    if (typeof obj !== 'undefined') { this.value = obj; }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void { }
  //#endregion

}
