import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import { MessageBusEvent, MessageBusEventType } from '../models';

@Injectable({providedIn: 'root'})
export class MessageBusService {
  private _message: Subject<MessageBusEvent>;
  constructor() {
    this._message = new Subject<MessageBusEvent>();
  }

  /**
   * Broadcasts an event to anybody listening for it.
   * @param event The event we are broadcasting.
   */
  public broadcast(event: MessageBusEvent) {
    this._message.next(event);
  }

  /**
   * Listens to the changes on the given event type.
   * @param type The event to listen to.
   * @ readonly the observable of the event we can subscribe to its changes.
   */
  public on<T>(type: MessageBusEventType): Observable<T> {
    return this._message.asObservable().filter(event => event.type === type).map(event => <T>event.data);
  }

}
