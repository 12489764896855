import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dash-widget',
  templateUrl: './dash-widget.component.html',
  styleUrls: ['./dash-widget.component.scss']
})
export class DashWidgetComponent implements OnInit {
  /**
   * The card main header title
   */
  @Input() title: string;
  /**
   * The bootstrap widget size. Could also be any other sizing class.
   */
  @Input() widgetSizeClass = 'col-md-3 col-sm-6 col-xs-12';
  /**
   * The card background class.
   */
  @Input() backgroundColorClass = 'white';
  /**
   * The card font color.
   */
  @Input() fontColorClass = 'bg-primary';
  /**
   * The card media location. Defaults to none. Used for placing icon on the card.
   */
  @Input() mediaLocation: 'none' | 'left' | 'right' = 'none';
  /**
   * The media header if set.
   */
  @Input() mediaHeader: string;
  /**
   * The card media header color.
   */
  @Input() mediaHeaderColorClass = 'black';
  /**
   * The card media text if any.
   */
  @Input() mediaText: string;
  /**
   * The card media text color class.
   */
  @Input() mediaTextColorClass = 'black';
  /**
   * The card media icon.
   */
  @Input() mediaIconClass: string;
  /**
   * The card media icon font class.
   */
  @Input() mediaIconFontClass = 'font-large-2';
  /**
   * Any image for the card if any. This is placed at the top of the card then content goes below it.
   */
  @Input() cardImageSource: string;
  /**
   * The card image alt property if image is set.
   */
  @Input() cardImageAlt = '';
  /**
   * The content title we are placing in the card's card block.
   */
  @Input() contentTitle: string;
  /**
   * The card content placed in the card block.
   */
  @Input() cardContent: string;
  /**
   * HTML card content. Set either content or html.
   */
  @Input() cardContentHtml: string;
  @Input() cardFooterCssClass = '';
  @Input() cardFooter: string;
  @Input() cardFooterHtml: string;
  constructor() { }

  ngOnInit() {
  }

}
