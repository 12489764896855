import { RouteInfo, Profile } from '../models';

// Sidebar menu Routes and data
export function ROUTES(profile?: Profile): RouteInfo[] {
    return [
        {
            path: '/panel/boards', title: 'Dashboard', icon: 'ft-home', class: '', badge: '',
            badgeClass: '', isExternalLink: false, submenu: [], permission: 'access_dashboard',
        },
        {
            path: '/panel/settings', title: 'Settings', icon: 'fa fa-building', class: '',
            badge: '', badgeClass: '', isExternalLink: false, submenu: [], permission: 'access_corporate_module',
        },
        {
            path: '/panel/customers', title: 'Customers', icon: 'fa fa-users', class: '', badge: '',
            badgeClass: '', isExternalLink: false, submenu: [], permission: 'access_customer_module',
        },
        {
            path: '/panel/loans', title: 'Loans', icon: 'fa fa-coins', class: '', badge: '',
            badgeClass: '', isExternalLink: false, submenu: [], permission: 'access_loan_module',
        },
        {
            path: '/panel/savings', title: 'Savings', icon: 'fa fa-wallet', class: '', badge: '',
            badgeClass: '', isExternalLink: false, submenu: [], permission: 'access_saving_module',
        },
        // {
        //     path: '/panel/inventory', title: 'Inventory', icon: 'fa-boxes', class: '', badge: '',
        //     badgeClass: '', isExternalLink: false, submenu: [], permission: 'Access_Inventory_Module',
        // },
        // {
        //     path: '/panel/purchasing', title: 'Purchases', icon: 'fa-sort-amount-up', class: '', badge: '',
        //     badgeClass: '', isExternalLink: false, submenu: [], permission: 'Access_Purchasing_Module',
        // },
        {
            path: '/panel/money-transfers', title: 'Transfers', icon: 'fa-sort-amount-down', class: '', badge: '',
            badgeClass: '', isExternalLink: false, submenu: [], permission: 'access_money_transfer_module',
        },
        {
            path: '/panel/users', title: 'Users', icon: 'icon-users', class: '', badge: '',
            badgeClass: '', isExternalLink: false, submenu: [], permission: 'access_user_module',
        },
        {
            path: '/panel/reports', title: 'Reports', icon: 'ft-package', class: '', badge: '',
            badgeClass: '', isExternalLink: false, permission: 'access_report_module', submenu: []
        },
        {
            path: '/panel/releases', title: 'Release', icon: 'ft-package', class: '', badge: '',
            badgeClass: '', isExternalLink: false, submenu: []
        },
        {
            path: '/panel/transactions', title: 'All', icon: 'ft-package', class: '', badge: '',
            badgeClass: '', isExternalLink: false, permission: 'access_report_module', submenu: []
        },
        {
            path: '/panel/bulk', title: 'All', icon: 'ft-package', class: '', badge: '',
            badgeClass: '', isExternalLink: false, permission: 'access_bulk_module', submenu: []
        }
    ];
}
