import { NgbDateStruct, NgbDate, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NgbCustomDateAdapter extends NgbDateAdapter<string> {
    /**
     * Converts the string value to struct.
     * @param value The value of date to convert.
     */
    fromModel(value: string): NgbDateStruct {
        if (value) {
            if (typeof value === 'string') {
                value = value.trim();
            }
            const mdt = moment(value);
            if (mdt.isValid()) {
                return new NgbDate(mdt.year(), mdt.month() + 1, mdt.date());
            }
        }
    }

    /**
     * Converts the date struct to a string representation.
     * @param date The date struct to convert to string representation.
     */
    toModel(date: NgbDateStruct): string {
        if (date) {
            return `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`;
        }
    }
}

/** Ngb Date formatter provider providers in outside-in order */
export const ngbDateAdapterProviders = [
    { provide: NgbDateAdapter, useClass: NgbCustomDateAdapter },
];
