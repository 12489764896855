import { Injectable } from '@angular/core';
import { SharedService } from '../shared.service';

@Injectable()
export class LookupService {

  constructor(private sharedService: SharedService) { }

  /**
   * Retrieve the identity types available for use.
   */
  identities<T>() {
    return this.sharedService.postWrapper<T>('lookup/identities/');
  }
  /**
   * Retrieve the Destinations available for use.
   */
  destinations<T>(data: any) {
    return this.sharedService.postWrapper<T>('lookup/destinations/', data);
  }

  /**
   * Retrieve the regions available for use.
   */
  regions<T>() {
    return this.sharedService.postWrapper<T>('lookup/regions/');
  }

  /**
   * Retrieve the religions available for use.
   */
  religions<T>() {
    return this.sharedService.postWrapper<T>('lookup/religions/');
  }

  /**
   * Retrieve the banks available for use.
   */
  banks<T>() {
    return this.sharedService.postWrapper<T>('lookup/banks/');
  }

  /**
   * Retrieve the borrower types available for use.
   */
  borrowerTypes<T>() {
    return this.sharedService.postWrapper<T>('lookup/borrower_types/');
  }

  /**
   * Retrieve the education levels available for use.
   */
  educationLevels<T>() {
    return this.sharedService.postWrapper<T>('lookup/education_levels/');
  }

  /**
   * Retrieve the corporate users that can be assigned in a dropdown.
   */
  corporateUsers<T>(data: any) {
    return this.sharedService.postWrapper<T>('lookup/corporate_users/', data);
  }

  /**
   * Retrieve the corporate branches that can be assigned in a dropdown.
   */
  corporateBranches<T>(data: any) {
    return this.sharedService.postWrapper<T>('lookup/corporate_branches/', data);
  }

  /**
   * Retrieve the languages that can be assigned in a dropdown.
   */
  languages<T>() {
    return this.sharedService.postWrapper<T>('lookup/languages/');
  }

  /**
   * Retrieve the salutations that can be assigned in a dropdown.
   */
  salutations<T>() {
    return this.sharedService.postWrapper<T>('lookup/salutations/');
  }

  /**
   * Retrieve the Marital Status that can be assigned in a dropdown.
   */
  marital_status<T>() {
    return this.sharedService.postWrapper<T>('lookup/marital_status/');
  }

  /**
   * Retrieve the relations that can be assigned in a dropdown.
   */
  relations<T>() {
    return this.sharedService.postWrapper<T>('lookup/relations/');
  }

  /**
   * Retrieve the employment types that can be assigned in a dropdown.
   */
  employmentTypes<T>() {
    return this.sharedService.postWrapper<T>('lookup/employment_types/');
  }

  /**
     * getRoles
     * Retrieves all the corporate users from the core.
     */
  getCorporateRoles<T>() {
    // data.client_id = environment.clientId;
    return this.sharedService.postWrapper<T>('lookup/corporate_user_roles/');
  }

  /**
   * Retrieve the account types that can be assigned in a dropdown.
   */
  accountTypes<T>(data: any) {
    return this.sharedService.postWrapper<T>('lookup/account_types/', data);
  }

  durations<T>() {
    return this.sharedService.postWrapper<T>('lookup/durations/');
  }

  scheduleTypes<T>() {
    return this.sharedService.postWrapper<T>('lookup/schedule_types/');
  }

  productGroups<T>() {
    return this.sharedService.postWrapper<T>('lookup/product_groups/');
  }

  /**
   * Retrieves the checkoff organizations available that can be assigned to a corporate.
   */
  checkoffOrganizations<T>() {
    return this.sharedService.postWrapper<T>('lookup/checkoff_organizations/');
  }

  /**
   * Retrieve the document types that can be assigned in a dropdown.
   */
  documentTypes<T>() {
    return this.sharedService.postWrapper<T>('lookup/document_types/');
  }

  /**
   * Retrieve the credibility checks available for use.
   */
  credibilityChecks<T>() {
    return this.sharedService.postWrapper<T>('lookup/credibility_checks/');
  }

  /**
   * Retrieve the products available for use.
   */
  products<T>(data: any) {
    return this.sharedService.postWrapper<T>('lookup/products/', data);
  }

  /**
   * Retrieve the interest types available for use.
   */
  interestTypes<T>() {
    return this.sharedService.postWrapper<T>('lookup/interest_types/');
  }

  /**
   * Retrieve the grace periods available for use.
   */
  gracePeriods<T>() {
    return this.sharedService.postWrapper<T>('lookup/grace_periods/');
  }

  /**
   * Retrieve the message types available for use.
   */
  messageTypes<T>() {
    return this.sharedService.postWrapper<T>('lookup/message_types/');
  }

  /**
   * Retrieve the bulk types that can be assigned in a dropdown.
   */
  bulkTypes<T>() {
    return this.sharedService.postWrapper<T>('lookup/bulk_types/', {});
  }

  /**
   * Retrieve the product groups that can be assigned in a dropdown.
   */
  productGroup<T>() {
    return this.sharedService.postWrapper<T>('lookup/product_groups/');
  }

  /**
   * productCorporateCredCheck
   * Retrieve the product corporate credibility check types that can be assigned in a dropdown.
   */
  productCorporateCredCheck<T>() {
    return this.sharedService.postWrapper<T>('lookup/product_corporate_credibility_check/');
  }

  /**
   * Retrieves the checkoff organizations for the current corporate
   */
  corporateCheckoffOrganizations<T>() {
    return this.sharedService.postWrapper<T>('lookup/corporate_checkoff_organizations/');
  }

  /**
   * Retrieve the corporate that can be assigned in a dropdown for switching.
   */
  switchableCorporates<T>() {
    return this.sharedService.postWrapper<T>('lookup/switchable_corporates/');
  }

  /**
   * Retrieves all the corporate that can be assigned in a dropdown.
   */
  allCorporates<T>() {
    return this.sharedService.postWrapper<T>('lookup/corporates/');
  }

  /**
   * Retrieve the bulk types that can be assigned in a dropdown.
   */
  corporateVoteEntries<T>() {
    return this.sharedService.postWrapper<T>('lookup/corporate_votes/', {});
  }

  /**
   * Retrieve loan collateral types in a dropdown.
   */
  corporateLoanCollateral<T>(data: any) {
    return this.sharedService.postWrapper<T>('lookup/corporate_credit_securities/', data);
  }

  /**
   * Retrieve loan collateral types in a dropdown.
   */
  creditSecurityTypes<T>(data: any) {
    return this.sharedService.postWrapper<T>('lookup/credit_security_types/', data);
  }

  /**
   * Retrieve Customer collateral in a dropdown.
   */
  customerCreditSecurity<T>(data: any) {
    return this.sharedService.postWrapper<T>('lookup/customer_credit_security/', data);
  }

  /**
   * Retrieve Base Actions in a dropdown.
   */
  baseActions<T>(data: any) {
    return this.sharedService.postWrapper<T>('lookup/actions_base/', data);
  }

}
