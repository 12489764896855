import { NgbDateParserFormatter, NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NgbDateFormatter extends NgbDateParserFormatter {
    /**
     * Our date parser string.
     */
    readonly DT_FORMAT = 'YYYY-MM-DD';

    /**
     * Parses the string value to the NgbDate struct.
     * @param value String to convert to date struct.
     */
    parse(value: string): NgbDateStruct {
        if (value) {
            value = value.trim();
            const mdt = moment(value, this.DT_FORMAT);
            if (mdt.isValid()) {
                return new NgbDate(mdt.year(), mdt.month() + 1, mdt.date());
            }
        }
        return null;
    }

    /**
     * Converts the date struct to string representation.
     * @param date The date struct to convert.
     */
    format(date: NgbDateStruct): string {
        if (!date) { return ''; }
        const mdt = moment([date.year, date.month - 1, date.day]);
        if (!mdt.isValid()) { return ''; }
        return mdt.format(this.DT_FORMAT);
    }
}

/** Ngb Date formatter provider providers in outside-in order */
export const ngbDateFormatterProviders = [
    { provide: NgbDateParserFormatter, useClass: NgbDateFormatter },
];
