import { Component, OnInit, Input, ElementRef, EventEmitter, Output } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { AuthService } from '../auth/auth.service';
import { RouteInfo, Profile } from '../models';
import { Router } from '@angular/router';
import { Select2OptionData } from '../components/select2/select2.models';
import { SafeUrl } from '@angular/platform-browser';
import { FileManagerService } from '../services/file-manager.service';

declare var $: any;

const fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
};

@Component({
    selector: 'ox-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    public menuItems: any[];
    isLoaded = false;
    @Input() loadingError = false;
    istoggled: boolean = true;
    profile: Profile;
    profile1: Profile;
    toggleState: string = localStorage.getItem('sidebar_state') || 'collapsed';
    profileImageUrl: string | SafeUrl = `assets/img/user.png`;
    selectedMenu = '';
    classApplied = false;
    public corporates: Array<Select2OptionData>;
    public selectedCorporateId = '';
    public selectedCorporateName = '';

    constructor(
        private router: Router,
        private elementRef: ElementRef,
        public auth: AuthService,
        private fileManager: FileManagerService) { }

    ngOnInit() {
        this.profile1 = this.auth.retrieveLocalUserData();
        // this.menuItems = this.filterMenu();
        this.isLoaded = true;
        const menustr = this.router.url;
        const menustrsplit = menustr.split('/');
        this.selectedMenu = menustrsplit[2];
        this.menuItems = ROUTES(this.profile);
        this.selectedCorporateName = this.profile.organization_white_label;
        

    //     setTimeout(() => {
    //         $.getScript('./assets/js/app-sidebar.js');
    //         // this.elementRef.nativeElement.querySelector('#sidebarToggle')
    //         //     .addEventListener('click', this.onClick.bind(this));
    //     }, 300);
    }

    onClick(_: any) {
        // initialize window resizer event on sidebar toggle click event
        setTimeout(() => { fireRefreshEventOnWindow(); }, 300);
    }

    toggleSidenav() {
        this.istoggled = !this.istoggled;
        this.toggleChange.emit(this.istoggled);
      }
    
    toggledSidenav(){
        return this.istoggled
    }

    expandSidenav() {
        this.istoggled = true;
      }

    collapseSidenav() {
        this.istoggled = false;
      }

    /**
     * Filter the menu items based on the permissions of the currently logged in user.
     * @param routes The routes to filter. defaults to ROUTES imported.
     */
    filterMenu(routes: RouteInfo[] = ROUTES(this.profile)): RouteInfo[] {
        if (!this.profile) { return routes; }
        const permissions = this.profile.permissions;
        return routes.filter(menu => {
            if (menu.displayCallback) {
                if (!menu.displayCallback()) {
                    return null;
                }
            }
            if (menu.permission) {
                if (permissions.indexOf(menu.permission) > -1) {
                    return this.filterSubmenus(menu, permissions);
                }
                return null;
            } else {
                return this.filterSubmenus(menu, permissions);
            }
        });
    }

    filterSubmenus(routeInfo: RouteInfo, permissions: string[]): RouteInfo {
        if (routeInfo) {
            if (routeInfo.submenu && routeInfo.submenu.length > 0) {
                routeInfo.submenu = routeInfo.submenu.filter(menu => {
                    if (menu.displayCallback) {
                        if (!menu.displayCallback()) {
                            return null;
                        }
                    }
                    if (menu.permission) {
                        if (permissions.indexOf(menu.permission) > -1) {
                            return this.filterSubmenus(menu, permissions);
                        }
                        return null;
                    } else {
                        return this.filterSubmenus(menu, permissions);
                    }
                });
            }
        }
        return routeInfo;
    }

    generateProfileImagePreview() {
        if (this.profile) {
            if (this.profile.profile_image_id) {
                this.fileManager
                .downloadWrapper('file_manager/download/', {id: this.profile.profile_image_id}).then(resp => {
                    if (resp.ok) {
                        this.profileImageUrl = this.fileManager.previewFile(resp);
                    }
                });
            }
        }
    }

    loadSettings() {
        this.router.navigate(['/', 'panel', 'settings']);
    }


    getMenu(v: any) {
        if (v === 'boards') {
            this.selectedMenu = 'boards';
            this.router.navigate(['/', 'panel', 'boards']);
        } else if (v === 'customers') {
            this.selectedMenu = 'customers';
            this.router.navigate(['/', 'panel', 'customers']);
        } else if (v === 'loans') {
            this.selectedMenu = 'loans';
            this.router.navigate(['/', 'panel', 'loans']);
        } else if (v === 'transfer') {
            this.selectedMenu = 'transfer';
            this.router.navigate(['/', 'panel', 'money-transfers']);
        } else if (v === 'savings') {
            this.selectedMenu = 'savings';
            this.router.navigate(['/', 'panel', 'savings']);
        // }  else if (v === 'purchasing') {
        //     this.selectedMenu = 'purchasing';
        //     this.router.navigate(['/', 'panel', 'purchasing']);
        // }  else if (v === 'sales') {
        //     this.selectedMenu = 'sales';
        //     this.router.navigate(['/', 'panel', 'sales']);
        }  else if (v === 'users') {
            this.selectedMenu = 'users';
            this.router.navigate(['/', 'panel', 'users']);
        } else if (v === 'settings') {
            this.selectedMenu = 'settings';
            this.router.navigate(['/', 'panel', 'settings']);
        }  else if (v === 'releases') {
            this.selectedMenu = 'releases';
            this.router.navigate(['/', 'panel', 'releases']);
        }  else if (v === 'transactions') {
            this.selectedMenu = 'transactions';
            this.router.navigate(['/', 'panel', 'transactions']);
        }  else if (v === 'reports') {
            this.selectedMenu = 'reports';
            this.router.navigate(['/', 'panel', 'reports']);
        }  else if (v === 'bulk') {
            this.selectedMenu = 'bulk';
            this.router.navigate(['/', 'panel', 'bulk']);
        }
    }
}
