import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'infinityZero'
})
export class InfinityZeroPipe implements PipeTransform {
  /**
   * Attempts to format the number in a presentable manner.
   */
  public static formatNumber(value: any) {
    try {
      return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    } catch (error) { }
    return value;
  }

  public static translate(value: any) {
    try {
      const zeroValue = parseFloat(value);
      if (zeroValue <= 0) {
        return '~';
      }
    } catch (error) { }
    return InfinityZeroPipe.formatNumber(value);
  }

  transform(value: any, _args?: any): any {
    return InfinityZeroPipe.translate(value);
  }

}
