import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RouteInfo, Profile } from '../../models';
import { ROUTES } from '../../sidebar/sidebar-routes.config';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'ox-top-menu-navigation',
  templateUrl: './top-menu-navigation.component.html',
  styleUrls: ['./top-menu-navigation.component.scss']
})
export class TopMenuNavigationComponent implements OnInit {
  public menuItems: RouteInfo[];
  @Input() isLoaded = false;
  @Input() loadingError = false;
  profile: Profile;
  currentPath: string;
  constructor(private router: Router, public auth: AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES(this.profile);
    const currentCoreRoute = this.menuItems
      .find((ri: RouteInfo, _index: number, _arr: RouteInfo[]) => this.router.url.startsWith(ri.path));
    this.currentPath = currentCoreRoute ? currentCoreRoute.path : '';
  }

  attemptNavigation() {
    this.router.navigateByUrl(this.currentPath);
  }
}
