import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UploadEvent } from '../models';
import { environment } from '../../../environments/environment';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
  private serverUrl = environment.serverUrl;
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private authService: AuthService) { }

  /**
   * The POST requests wrapper to send download request to the server.
   * @param endpoint The endpoint we are calling at core.
   * @param data The data we are sending to core.
   */
  public downloadWrapper(endpoint: string, data?: any): Promise<HttpResponse<Blob>> {
    if (data) {
      data['target'] = endpoint;
    } else {
      data = { target: endpoint };
    }
    return this.http
      .post<Blob>(this.serverUrl + `api/${endpoint}`, data, { observe: 'response', responseType: 'blob' as 'json' }).toPromise();
  }

  /**
   * Uploads a file to the given endpoint.
   * @param endpoint The core endpoint we are sending the data to.
   * @param formData The form data we are sending to the server.
   */
  public uploadFile(endpoint: string, formData: FormData): Observable<UploadEvent> {
    try {
      const progressReport = new Subject<UploadEvent>();
      const oReq = new XMLHttpRequest();
      oReq.open('POST', `${this.serverUrl}api/${endpoint}`, true);
      oReq.onprogress = (pEVent: ProgressEvent) => progressReport.next(
        { event: pEVent, source: oReq, complete: false });
      oReq.onload = (oEvent: ProgressEvent) => {
        progressReport.next({ event: oEvent, source: oReq, complete: true });
        progressReport.complete();
      };
      oReq.onerror = (oEvent: any) => progressReport.error(oEvent);
      // Append the necessary data
      formData.append('token', this.authService.getToken());
      formData.append('client_id', environment.clientId);
      formData.append('target', endpoint);

      oReq.send(formData);
      return progressReport.asObservable();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Auto-magically download a file returned as HttpResponse from an API request.
   * @param response The HttpResponse containing a blob item to be rendered.
   */
  downloadFile(response: HttpResponse<Blob>) {
    let filename: any;
    try {
      const filenameAttachment = response.headers.get('content-disposition').split(';')[1];
      filename = filenameAttachment.replace('filename = "', '').replace('filename="', '')
        .replace('filename= "', '').replace('filename ="', '').replace('"', '');
    } catch (error) {
      // TODO: Just read the content type and try to figure out what it might be from a collection of knowns!
      filename = 'unknown-format-bad-file';
    }
    const url = window.URL.createObjectURL(response.body);
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.setAttribute('href', url);
    downloadLink.setAttribute('download', filename);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
  }

  /**
   * Allows generation of a preview link that can be used in images and content downloads.
   * @param response The HttpResponse with a blob content that we want to generated a safe URL for it.
   * @returns The trusted URL that can be used in things like img tags.
   */
  previewFile(response: HttpResponse<Blob>) {
    let fileType: any;
    try {
      fileType = response.headers.get('content-type');
    } catch (error) {
      // TODO: Just read the content type and try to figure out what it might be from a collection of knowns!
      fileType = 'text/plain';
    }
    return this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(new Blob([response.body], { type: fileType })));
  }
}
