import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input, HostListener } from '@angular/core';

import * as d3 from 'd3';
import { GraphDatum } from './graph-wrapper.model';

@Component({
  selector: 'graph-wrapper',
  templateUrl: './graph-wrapper.component.html',
  styleUrls: ['./graph-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GraphWrapperComponent implements OnInit {
  /**
   * The root component with the required data.
   */
  root = null;
  /**
   * The layout we use to render the tree.
   */
  treeLayout: d3.TreeLayout<{}> = null;
  /**
   * The width of the content area.
   */
  viewerWidth = 100;
  /**
   * The height of the content area.
   */
  viewerHeight = 100;

  /**
   * The scaling of the width.
   */
  widthScale = d3.scaleLinear().domain([1, 100]).range([1, 10]);

  /**
   * Define the margins that we require for our container.
   */
  margin = { top: 40, right: 20, bottom: 20, left: 40 }

  /**
   * The transitioning duration.
   */
  duration = 750;

  /**
   * The svg container we are using to joke about graphs.
   */
  svg = null;

  /**
   * The tooltip container on hover.
   */
  tooltip = null;

  /**
   * The textarea container we copy contents to in order to select it.
   */
  copyTextArea = null;

  /**
   * The nodes indexer while looping through.
   */
  index = 0;

  /**
   * The graph wrapper container.
   */
  @ViewChild('graphWrapper') graphWrapper: ElementRef;

  /**
   * The graph data to use for rendering the graph.
   */
  @Input() graphData: GraphDatum;

  /**
   * Stores the current mouse position of the svg content on mouse hover.
   */
  currentMousePos = [0, 0];

  constructor() { }

  ngOnInit() {
    this.viewerWidth = $(this.graphWrapper.nativeElement).width();
    this.viewerHeight = $(this.graphWrapper.nativeElement).height();

    if (this.graphData) {
      this.generateTree(this.graphData);
    }
  }

  /**
   * Generates the svg we use to kimba kimbia kimbi kimbi.
   */
  generateSvg() {
    return d3.select(this.graphWrapper.nativeElement).append("svg")
      .attr("width", this.viewerWidth)
      .attr("height", this.viewerHeight)
      .append("g")
      .attr("transform", `translate(${80},${5})`);
  }

  /**
   * Generates the tooltip component for rendering tooltips.
   */
  generateTooltip() {
    return d3.select(this.graphWrapper.nativeElement).append("div")
      .attr("class", "node-tooltip")
      .style("opacity", 0);
  }

  /**
   * Generates the tooltip component for rendering tooltips.
   */
  generateSelectTextarea() {
    return d3.select(this.graphWrapper.nativeElement).append("textarea")
      .attr("readonly", "")
      .style("position", "absolute")
      .style("left", "-9999px");
  }

  /**
   * Generates the diagnol of the given entities.
   * @param s The source target.
   * @param d The destination target.
   */
  diagonal(s, d) {
    return `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`;
  }

  /**
   * Collapse the children of the given tree.
   * @param d The parent node we are looking at its children.
   */
  public collapse = (d) => {
    if (d.children) {
      d._children = d.children
      d._children.forEach(this.collapse)
      d.children = null
    }
  }

  /**
   * Returns the text color to be used for the texts and circle 
   * depending on the type of element we are working with.
   */
  public getTextColor = (d) => {
    if (!d.data.registered) {
      return 'rgb(170,168,168)';
    } else if (d.data.target) {
      return 'rgb(0,0,205)';
    } else if (d.data.inviter) {
      return 'rgb(255,0,255)';
    } else {
      return 'rgb(47,79,79)';
    }
  }

  /**
   * Retrieves the x-axis for the text.
   */
  public getNodeTextX = (d) => {
    if (d.data.target || d.data.inviter) {
      let chars = 0;
      try {
        chars = d.data.name.length * 6;
        chars /= 2;
      } catch (error) { }
      return -chars;
    }
    return d.children || d._children ? -20 : 20;
  }

  /**
   * Retrieves the y-axis for the text.
   */
  public getNodeTextY = (d) => {
    if (d.data.target) {
      return '2.8em';
    } else if (d.data.inviter) {
      return '2.4em';
    }
    return '.35em';
  }

  /**
   * Retrieves the text anchor for the x-axis.
   */
  public getNodeTextAnchorX = (d) => {
    if (d.data.target || d.data.inviter) {
      return 'bottom';
    }
    return d.children || d._children ? "end" : "start";
  }

  /**
   * Generates the actual tree to render.
   * @param data The data to render with.
   */
  generateTree(data: GraphDatum) {
    this.svg = this.generateSvg();
    this.tooltip = this.generateTooltip();
    this.copyTextArea = this.generateSelectTextarea();
    // declares a tree layout and assigns the size
    this.treeLayout = d3.tree().size([this.viewerHeight, this.viewerWidth]);
    // Assigns parent, children, height, depth
    this.root = d3.hierarchy(data, d => d.children);
    this.root.x0 = this.viewerHeight / 2;
    this.root.y0 = 0;
    // Collapse after the second level (if any)
    if (this.root.children) {
      this.root.children.forEach(this.collapse);
    }
    // Update the tree
    this.update(this.root);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    this.currentMousePos = [e.clientX, e.clientY];
  }

  /**
   * Updates the nodes accordingly.
   * @param source The source element to update.
   */
  update(source) {
    // Assigns the x and y position for the nodes
    var treeData = this.treeLayout(this.root);

    // Compute the new tree layout.
    var nodes = treeData.descendants(),
      links = treeData.descendants().slice(1);

    // Normalize for fixed-depth.
    nodes.forEach(d => { d.y = d.depth * 180 });

    // ****************** Nodes section ***************************

    // Update the nodes...
    var node = this.svg.selectAll('g.node')
      .data(nodes, d => d.id || (d.id = ++this.index));

    // Enter any new modes at the parent's previous position.
    var nodeEnter = node.enter().append('g')
      .attr('class', 'node')
      .attr("transform", _d => `translate(${source.y0},${source.x0})`)
      .on('click', this.click)
      .on('mouseover', this.openTooltip)
      .on('mousemove', this.mouseMove)
      .on('mouseout', this.closeTooltip)
      .on("contextmenu", (d, _i) => {
        d3.event.preventDefault();
        this.copyToClipboard(d.data.phone);
      });

    // Add Circle for the nodes
    nodeEnter.append('circle')
      .attr('class', 'node')
      .attr('r', 1e-6)
      .style("fill", d => d._children ? "lightsteelblue" : "#fff")
      .style("stroke", d => this.getTextColor(d))
      .style("stroke-dasharray", d => d.data.target ? 2 : 0);

    // Add labels (initials) for the nodes
    nodeEnter.append("text")
      .attr("dy", ".35em")
      .attr("dx", _d => -10)
      .text(d => d.data.label)
      .style("fill", "black")
      .attr('cursor', 'pointer');

    // Add labels(name) for the nodes
    nodeEnter.append('text')
      .attr("dy", d => this.getNodeTextY(d))
      .attr("x", d => this.getNodeTextX(d))
      .attr("text-anchor", d => this.getNodeTextAnchorX(d))
      .text(d => d.data.name)
      .style("fill", d => this.getTextColor(d));

    // UPDATE
    var nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position for the node
    nodeUpdate.transition()
      .duration(this.duration)
      .attr("transform", d => `translate(${d.y},${d.x})`);

    // Update the node attributes and style
    nodeUpdate.select('circle.node')
      .attr('r', d => d.data.target ? 23 : 17)
      .style("fill", d => d._children ? "lightsteelblue" : "#fff")
      .attr('cursor', 'pointer');


    // Remove any exiting nodes
    var nodeExit = node.exit().transition()
      .duration(this.duration)
      .attr("transform", _d => `translate(${source.y},${source.x})`)
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select('circle').attr('r', 1e-6);

    // On exit reduce the opacity of text labels
    nodeExit.select('text').style('fill-opacity', 1e-6);

    // ****************** links section ***************************

    // Update the links...
    var link = this.svg.selectAll('path.node-link')
      .data(links, d => d.id)
      .style('stroke-width', d => this.widthScale(d.data.value));

    // Enter any new links at the parent's previous position.
    var linkEnter = link.enter().insert('path', "g")
      .attr("class", "node-link")
      .attr('d', _d => {
        var o = { x: source.x0, y: source.y0 }
        return this.diagonal(o, o)
      })
      .style('stroke-width', d => this.widthScale(d.data.value));

    // UPDATE
    var linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate.transition()
      .duration(this.duration)
      .attr('d', d => this.diagonal(d, d.parent));

    // Remove any exiting links
    var linkExit = link.exit().transition()
      .duration(this.duration)
      .attr('d', _d => {
        var o = { x: source.x, y: source.y }
        return this.diagonal(o, o)
      })
      .style('stroke-width', d => this.widthScale(d.data.value))
      .remove();

    // Store the old positions for transition.
    nodes.forEach(d => {
      (<any>d).x0 = d.x;
      (<any>d).y0 = d.y;
    });
  }

  /**
   * Handle click events fired within the wrapper.
   * @param d The parent that has been clicked.
   */
  public click = (d: any) => {
    if (d.data.inviter) return; // Prevent collapsing the inviter.
    if (d.children) {
      d._children = d.children;
      d.children = null;
    } else {
      d.children = d._children;
      d._children = null;
    }
    this.update(d);
  }

  /**
   * Displays the tooltip at the given position.
   * @param d The parent that has been hovered on.
   */
  public openTooltip = (d: any) => {
    const tipData = d.data;
    let tip = "";
    if (tipData.registered) {
      tip = `
      <table>
        <tr>
          <th colspan='2'>${tipData.name} : ${tipData.phone ? tipData.phone : ''}</th>
        </tr>
        <tr>
          <th>Loan Limit</th><td class="text-right">${tipData.loan_limit}</td>
        </tr>
        <tr>
          <th>Loan Balance</th><td class="text-right">${tipData.loans_balance}</td>
        </tr>
        <tr>
          <th>Wallet Balance</th><td class="text-right">${tipData.wallet_balance}</td>
        </tr>
        <tr>
          <th>Loans Requested</th><td class="text-right">${tipData.loans_amount} [${tipData.loans_count}]</td>
        </tr>
        <tr>
          <th>Loans Paid</th><td class="text-right">${tipData.loans_completed_amount} [${tipData.loans_completed_count}]</td>
        </tr>
        <tr>
          <th>Defaulted Within 6 Months</th><td class="text-right">${tipData.defaulted}</td>
        </tr>
        <tr>
          <th>Registration</th><td class="text-right">${tipData.date_created}</td>
        </tr>
        <tr>
          <th>Gender</th><td class="text-right">${tipData.gender}</td>
        </tr>
        <tr>
          <th>Referrals #</th><td class="text-right">${tipData.invites}</td>
        </tr>
      </table>
    `;
    } else {
      tip = `
      <table>
        <tr>
          <th colspan='2'>${tipData.phone ? tipData.phone : ''}</th>
        </tr>
        <tr>
          <th>Referral</th><td>Pending</td>
        </tr>
      </table>
      `;
    }

    this.tooltip.transition()
      .duration(1000)
      .style("opacity", .9);

    this.tooltip.html(tip);
  }

  /**
   * Closes the tooltip with a fancy animation.
   */
  public closeTooltip = (d: any) => {
    this.tooltip.transition()
      .duration(2000)
      .style("opacity", 0).style("top", `0px`).style("left", `0px`);
  }

  /**
   * Retrieve the mouse move event position to set the tooltip position accordingly.
   */
  public mouseMove = (_d, _i) => {
    const boundingRect = this.graphWrapper.nativeElement.getBoundingClientRect();
    this.setTooltipPosition(
      this.currentMousePos[0] - boundingRect.x + 60, this.currentMousePos[1] - boundingRect.y + 130);
  }

  /**
   * Separate method to set the position of the tooltip.
   */
  public setTooltipPosition(x: number, y: number) {
    this.tooltip.style("top", `${y}px`).style("left", `${x}px`);
  }

  /**
   * Copies the given text to the clipboard for the user to paste somewhere.
   */
  public copyToClipboard = (txt: string) => {
    const pasteBox = document.createElement('textarea');
    pasteBox.style.position = 'fixed';
    pasteBox.style.top = '0';
    pasteBox.style.left = '0';
    pasteBox.style.opacity = '0';
    document.body.appendChild(pasteBox);
    pasteBox.value = txt;
    pasteBox.select();
    document.execCommand('copy', false, null);
    document.body.removeChild(pasteBox);
  }
}
