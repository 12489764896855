import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appLoader2]'
})
export class Loader2Directive implements AfterViewInit {
  private animCanvas = `<span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>`;
  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    $(this.el.nativeElement).addClass('progress').append(this.animCanvas);
  }
}
