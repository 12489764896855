export class DashWidget {
    /**
     * The card title
     */
    title?: string;
    /**
     * The bootstrap widget size. Could also be any other sizing class.
     */
    widgetSizeClass? = 'col-md-12';
    /**
     * The card background class. Defaults to bg-primary.
     */
    backgroundColorClass? = 'white';
    /**
     * The card font color.
     */
    fontColorClass? = 'bg-primary';
    /**
     * The card media location. Defaults to none. Used for placing icon on the card.
     */
    mediaLocation?: 'none' | 'left' | 'right' = 'none';
    /**
     * The media header if set.
     */
    mediaHeader?: string;
    /**
     * The card media header color.
     */
    mediaHeaderColorClass? = 'black';
    /**
     * The card media text if any.
     */
    mediaText?: string;
    /**
     * The card media text color class.
     */
    mediaTextColorClass? = 'black';
    /**
     * The card media icon.
     */
    mediaIconClass?: string;
    /**
     * The card media icon font class.
     */
    mediaIconFontClass? = 'font-large-2';
    /**
     * Any image for the card if any. This is placed at the top of the card then content goes below it.
     */
    cardImageSource?: string;
    /**
     * The card image alt property if image is set.
     */
    cardImageAlt? = '';
    /**
     * The content title we are placing in the card's card block.
     */
    contentTitle?: string;
    /**
     * The card content placed in the card block.
     */
    cardContent?: string;
    /**
     * HTML card content. Set either content or html.
     */
    cardContentHtml?: string;
    /**
     * Card footer css classes.
     */
    cardFooterCssClass? = '';
    /**
     * Card footer text if any. Should be mutually exclusive with footer HTML.
     */
    cardFooter?: string;
    /**
     * Card footer HTML content if any. Mututally exclusive with the footer content text.
     */
    cardFooterHtml?: string;

    /**
     * Default constructor with no magic at all.
     */
    constructor() {}
}

export class DashWidgetGroup {
    /**
     * The dashboard group title.
     */
    title?: string;
    /**
     * The size of the container for this group. Could be bootstrap layout or custom class 
     * for controling the placement of elements in the container.
     */
    sizeCssClass? = 'col-xl-4 col-lg-6 col-md-6';
    /**
     * The widgets listing.
     */
    widgets: DashWidget[];
    constructor() {}
}
