import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appLoader]'
})
export class LoaderDirective implements AfterViewInit {
  private htmlContainer = `
    <div class='loader--dot'></div>
    <div class='loader--dot'></div>
    <div class='loader--dot'></div>
    <div class='loader--dot'></div>
    <div class='loader--dot'></div>
    <div class='loader--dot'></div>`;
  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    $(this.el.nativeElement).addClass('loader').append(this.htmlContainer);
  }
}
