import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'dateMyFormat'
})
export class DateMyFormatPipe implements PipeTransform {
  /**
     * Allows us to format the date time to month-date.
     * This makes this function re-usable when called directly.
     * @param value A date time string
     */
  static process(value: any): string {
    try {
      if (moment(value).isValid()) {
        return moment(value).format('MMM-YYYY');
      }
    } catch (error) {
      const passedDate = new Date(value);
      // tslint:disable-next-line: whitespace
      return `${passedDate.getMonth() - passedDate.getFullYear()}`;
    }
  }

  transform(value: any, args?: any): any {
    return DateMyFormatPipe.process(value);
  }

}
