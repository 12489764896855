// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  clientId: "af556ae0-bc4e-48cb-a6cb-14348af276c5", // Test server
  serverUrl: "https://api.360.zerahi.co/", // Prod server9
  // serverUrl: 'http://127.0.0.1:9097/',  // Local server
  appName: "Zerahi 360",
  appIcon: "assets/img/logo1.png",
};
