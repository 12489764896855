import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment'

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  /**
   * Allows us to format the date.
   * This makes this function re-usable when called directly.
   * @param value A date string
   */
  static process(value: any): string {
    try {
      if (moment(value).isValid()) {
        return moment(value).format('DD/MM/YYYY');
      } else {
        return '';
      }
    } catch (error) {
      return new Date(value).toLocaleDateString();
    }
  }

  transform(value: any, args?: any): any {
    return DateFormatPipe.process(value);
  }

}
