import { Component, OnInit, AfterViewInit, AfterContentInit, Output, EventEmitter, ContentChildren, QueryList } from '@angular/core';
import { StepperStepComponent } from './stepper-step.component';

export class WizardEvent {
  event: Event;
  currentIndex: number;
}

@Component({
  selector: 'stepper-wizard',
  templateUrl: './stepper-wizard.component.html',
  styleUrls: ['./stepper-wizard.component.scss']
})
export class StepperWizardComponent implements OnInit, AfterViewInit, AfterContentInit {
  @ContentChildren(StepperStepComponent)
  wizardSteps: QueryList<StepperStepComponent>;

  private _steps: Array<StepperStepComponent> = [];
  private _isCompleted: boolean = false;

  @Output()
  onStepChanged: EventEmitter<StepperStepComponent> = new EventEmitter<StepperStepComponent>();

  constructor() { }

  ngOnInit() {}

  ngAfterContentInit() {
    this.wizardSteps.forEach(step => this._steps.push(step));
    this.steps[0].isActive = true;
  }

  ngAfterViewInit() {}

  get steps(): Array<StepperStepComponent> {
    return this._steps.filter(step => !step.hidden);
  }

  get isCompleted(): boolean {
    return this._isCompleted;
  }

  get activeStep(): StepperStepComponent {
    return this.steps.find(step => step.isActive);
  }

  set activeStep(step: StepperStepComponent) {
    if (step !== this.activeStep && !step.isDisabled) {
      this.activeStep.isActive = false;
      step.isActive = true;
      this.onStepChanged.emit(step);
    }
  }

  public get activeStepIndex(): number {
    return this.steps.indexOf(this.activeStep);
  }

  get hasNextStep(): boolean {
    return this.activeStepIndex < this.steps.length - 1;
  }

  /**
   * Whether to display the done button or not.
   */
  public get showDone() : boolean {
    return this.activeStep.showDone;
  }
  

  get hasPrevStep(): boolean {
    return this.activeStepIndex > 0;
  }

  public goToStep(step: StepperStepComponent): void {
    if (!this.isCompleted) {
      this.activeStep = step;
    }
  }

  public next(): void {
    if (this.hasNextStep) {
      let nextStep: StepperStepComponent = this.steps[this.activeStepIndex + 1];
      this.activeStep.onNext.emit();
      nextStep.isDisabled = false;
      this.activeStep = nextStep;
    }
  }

  public previous(): void {
    if (this.hasPrevStep) {
      let prevStep: StepperStepComponent = this.steps[this.activeStepIndex - 1];
      this.activeStep.onPrev.emit();
      prevStep.isDisabled = false;
      this.activeStep = prevStep;
    }
  }

  public previousDisabled(): void {
    if (this.hasPrevStep) {
      let prevStep: StepperStepComponent = this.steps[this.activeStepIndex - 1];
      this.activeStep.onPrev.emit();
      this.activeStep.isDisabled = true;
      this.activeStep = prevStep;
    }
  }

  public complete(): void {
    this.activeStep.onComplete.emit();
    this._isCompleted = true;
  }

}
