import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesno'
})
export class YesNoPipe implements PipeTransform {
  static process(value: any): string {
    if (value) return 'Yes';
    else return 'No';
  }
  static processNoEmpty(value: any): string {
    if (value) return 'Yes';
    else return '';
  }
  transform(value: any, args?: any): string {
    return YesNoPipe.process(value);
  }
}
