import {
    Directive, ElementRef, AfterViewInit,
    Input, HostListener, NgModule
} from '@angular/core';

@Directive({
    selector: '[matchHeight]'
})
export class MatchHeightDirective implements AfterViewInit {
    // class name to match height
    @Input()
    matchHeight: string;

    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        // call our matchHeight function here
        setTimeout(() => { this.matchHeights(this.el.nativeElement, this.matchHeight) }, 2000);
    }

    matchHeights(parent: HTMLElement, className: string) {
        if (!parent) return;

        // step 1: find all the child elements with the selected class name
        const children = parent.getElementsByClassName(className);

        if (!children) return;

        let tallestElement = 0;

        // step 2: get the tallest element in the selection
        Array.from(children).forEach((x: HTMLElement) => {
            const h = x.getBoundingClientRect().height;
            if (h > tallestElement) tallestElement = h;
        });
        
        // step 3: update all the child elements to the tallest height
        if (typeof tallestElement == 'number') {
            Array.from(children).forEach((x: HTMLElement) => x.style.height = `${tallestElement}px`);
        }

        if (!parent.hasAttribute('heighmatched')) {
            this.setChangesObservation(children);
            parent.setAttribute('heighmatched', 'true');
        }        
    }

    /**
     * Set up observation of elements when they change or updated.
     */
    setChangesObservation(elements: NodeListOf<Element> | HTMLCollectionOf<Element>) {
        var observer = new MutationObserver((mutationsList, _) => {
            for (var mutation of mutationsList) {
                if (mutation.type === 'characterData') {
                    setTimeout(() => { this.matchHeights(this.el.nativeElement, this.matchHeight) }, 2000);
                    // Verify the results 2 seconds later
                    setTimeout(() => { this.matchHeights(this.el.nativeElement, this.matchHeight) }, 4000);
                }
            }
        });
        Array.from(elements)
            .forEach((x: HTMLElement) => {
                observer.observe(x, { attributes: true, childList: true, subtree: true, characterData: true });
            });
    }

    @HostListener('window:resize')
    onResize() {
        // call our matchHeight function here
        this.matchHeights(this.el.nativeElement, this.matchHeight);
    }

    @HostListener('DOMContentLoaded')
    onDahsboardLoaded() {
        // call our matchHeight function here
        this.matchHeights(this.el.nativeElement, this.matchHeight);
    }
}

@NgModule({
    declarations: [MatchHeightDirective],
    exports: [MatchHeightDirective]
})

export class MatchHeightModule { }