import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
// tslint:disable-next-line: directive-selector
  selector: '[appLoader3]'
})
export class Loader3Directive implements AfterViewInit {
  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    $(this.el.nativeElement).addClass('spinner-bubble').append('<div class="bounce1"></div>' +
      '<div class="bounce2"></div><div class="bounce3"></div>');
    // $(this.el.nativeElement).addClass('ball-clip-rotate-multiple spinner-loader').append('<div></div><div></div>');
  }
}
