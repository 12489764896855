import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessages } from './error-messages.enum';
import { HttpCancellableService } from './http-cancellable.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorHandlerProviderService implements ErrorHandler {

  constructor(private injector: Injector) { }

  handleError(error: Error | HttpErrorResponse): void {
    const toast = this.injector.get(ToastrService);
    const router = this.injector.get(Router);
    const cancelHttp = this.injector.get(HttpCancellableService);
    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        toast.error(ErrorMessages.OFFLINE, 'Connection Error', { timeOut: 5000, closeButton: true });
      } else {
        if (error.status === 0) {
          toast.error(ErrorMessages.OFFLINE, 'Connection Error', { timeOut: 5000, closeButton: true });
        }
        else if (error.status === 401) {
          toast.error(
            `${error.status} ${ErrorMessages.UNAUTHORIZED}`,
            'Authorization Error', { timeOut: 2000, closeButton: true });
        }
        else if (error.status === 403) {
          toast.error(
            `${error.status} ${ErrorMessages.DENIED}`,
            'Permission Denied', { timeOut: 2000, closeButton: true });
        }
        else if (error.status === 404) {
          toast.error(
            `${error.status} ${ErrorMessages.NOTFOUND}`, 'Not Found Error', { timeOut: 2000, closeButton: true });
        }
        else {
          toast.error(
            `${error.status}. ${ErrorMessages.HTTPDEFAULT}`, 'Http Error',
            { timeOut: 2000, closeButton: true });
        }
      }
    } else {
      if (!environment.production) {
        console.error(error);
      }
    }
  }
}
