import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {
  /**
   * Translates the value passed in as either male (true) or female (false)
   * @param value
   * @param args
   */
  public static transformBool(value: any): any {
    return value ? 'Male' : 'Female';
  }

  public static translate(value: any) {
    if (value === 'M') { return 'Male'; } else if (value === 'F') { return 'Female'; } else { return value; }
  }

  transform(value: any, _args?: any): any {
    return GenderPipe.translate(value);
  }

}
