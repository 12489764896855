// Core
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { NgbModule, NgbModalModule, NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TreeModule } from 'angular-tree-component';
import { MatchHeightModule } from './directives/match-height.directive';
import { DataTablesModule } from 'angular-datatables';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './modules/material.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';

// Factories
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Components
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DetailsRenderComponent } from './components/details-render/details-render.component';
import { StepperWizardComponent } from './components/stepper-wizard/stepper-wizard.component';
import { StepperStepComponent } from './components/stepper-wizard/stepper-step.component';
import { Select2Component } from './components/select2/select2.component';
import { DashWidgetComponent } from './components/dash-widget/dash-widget.component';
import { DashWidgetGroupComponent } from './components/dash-widget/dash-widget-group.component';
import { GraphWrapperComponent } from './components/graph-wrapper/graph-wrapper.component';
import { TopbarComponent } from './topbar/topbar.component';
import { TopMenuNavigationComponent } from './components/top-menu-navigation/top-menu-navigation.component';
import { CalendarWrapperComponent } from './components/calendar-wrapper/calendar-wrapper.component';
import { CalendarDateTimePickerComponent } from './components/calendar-wrapper/calendar-date-time-picker.component';

// Directives
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { LoaderDirective } from './directives/loader.directive';
import { Loader2Directive } from './directives/loader2.directive';
import { Loader3Directive } from './directives/loader3.directive';

// Pipes
import { YesNoPipe } from './pipes/yes-no.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { ValueNotSetPipe } from './pipes/value-not-set.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { DateMyFormatPipe } from './pipes/date-my-format.pipe';
import { InfinityZeroPipe } from './pipes/infinity-zero.pipe';
import { ValueOrQuestionPipe } from './pipes/value-or-question.pipe';

// Services
import { SwalService } from './services/swal.service';
import { LookupService } from './services/lookup.service';
import { SharedService } from './shared.service';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth-guard.service';
import { HttpCancellableService } from './providers/http-cancellable.service';
import { ErrorHandlerProviderService } from './providers/error-handler-provider.service';
import { NgbDateFRParserFormatter } from './providers/ngb-date-fr-parser-formatter';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgbModalModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        CalendarModule,
        TreeModule,
        DataTablesModule,
        MatchHeightModule,
        UiSwitchModule,
        MaterialModule,

        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        TopbarComponent,
        StepperWizardComponent,
        StepperStepComponent,
        DetailsRenderComponent,
        Select2Component,
        DashWidgetComponent,
        DashWidgetGroupComponent,
        GraphWrapperComponent,
        CalendarDateTimePickerComponent,
        CalendarWrapperComponent,

        ToggleFullscreenDirective,
        LoaderDirective,
        Loader2Directive,
        Loader3Directive,
        // ParticlesjsDirective,

        YesNoPipe,
        GenderPipe,
        ValueNotSetPipe,
        SafeHtmlPipe,
        DateFormatPipe,
        DateTimeFormatPipe,
        DateMyFormatPipe,
        InfinityZeroPipe,
        ValueOrQuestionPipe
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,
        NgbModule,
        NgbModalModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        TreeModule.forRoot(),
        DataTablesModule.forRoot(),
        ToastrModule.forRoot(),
        MatchHeightModule,
        UiSwitchModule,
        MaterialModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        TopbarComponent,
        StepperWizardComponent,
        StepperStepComponent,
        DetailsRenderComponent,
        Select2Component,
        DashWidgetComponent,
        DashWidgetGroupComponent,
        GraphWrapperComponent,
        CalendarDateTimePickerComponent,
        CalendarWrapperComponent,

        ToggleFullscreenDirective,
        LoaderDirective,
        Loader2Directive,
        Loader3Directive,
        // ParticlesjsDirective,

        YesNoPipe,
        GenderPipe,
        ValueNotSetPipe,
        SafeHtmlPipe,
        DateFormatPipe,
        DateTimeFormatPipe,
        DateMyFormatPipe,
        InfinityZeroPipe,
        ValueOrQuestionPipe,
        TopMenuNavigationComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                SharedService,
                SwalService, LookupService, AuthService,
                AuthGuard, HttpCancellableService, ErrorHandlerProviderService, NgbDateFRParserFormatter
            ]
        };
    }
}
