import { Component, OnInit, Input } from '@angular/core';
// import { ROUTES } from './topbar-routes.config';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../../../environments/environment';
import { RouteInfo, Profile, MessageBusEventType, ModuleMenu,  BaseResponse, Lookup } from '../models';
import { MessageBusService } from '../services/message-bus.service';
import { AuthService } from '../auth/auth.service';
import { SafeUrl } from '@angular/platform-browser';
import { Select2OptionData } from '../components/select2/select2.models';
import { LookupService } from '../services/lookup.service';
import { SwalService } from '../services/swal.service';


declare var $: any;

var fireRefreshEventOnWindow = function () {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
};


@Component({
    selector: 'ox-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
    currentLang = 'en';
    toggleClass = 'fas fa-expand';
    public menuItems: RouteInfo[];
    appName = environment.appName;
    appIcon = environment.appIcon;
    @Input() loadingError = false;
    profile: Profile;

    public corporates: Array<Select2OptionData>;
    public selectedCorporateId = '';
    public selectedCorporateName = '';

    /**
     * Whether we should display the top menu bar or not.
     */
    showTopMenu: boolean = false;
    /**
     * Control when the menu is loading to display the loader.
     */
    menuLoading: boolean = true;
    selectedMenu = '';

    constructor(private messageBus: MessageBusService, public auth: AuthService, public swUpdates: SwUpdate, private swalService: SwalService,
        private lookupService: LookupService,) {
        // Load the module menus.
        this.messageBus.on<ModuleMenu>(MessageBusEventType.moduleload).subscribe(menu => {
            if (menu) {
                this.showTopMenu = menu.showMenu;
                this.menuItems = menu.menus;
                this.menuLoading = false;
            } else {
                this.showTopMenu = false;
                this.menuItems = null;
                this.menuLoading = true;
            }
        });
        // uUload the module menus
        this.messageBus.on<any>(MessageBusEventType.moduleunload).subscribe(_ => {
            this.showTopMenu = false;
            this.menuItems = null;
            this.menuLoading = true;
        });
    }

    ngOnInit() {
        // this.menuItems = this.filterMenu();
        this.profile = this.auth.retrieveLocalUserData();
        this.messageBus.on(MessageBusEventType.profileupdated).subscribe(_ => {
            this.profile = this.auth.retrieveLocalUserData();
        });
        this.selectedCorporateName = this.profile.organization_white_label;
        if (this.auth.hasPermission('can_switch_corporate')) {
            this.pullCorporates();
        }
    }

    onClick(_: any) {
        //initialize window resizer event on sidebar toggle click event
        setTimeout(() => { fireRefreshEventOnWindow() }, 300);
    }

    ToggleClass() {
        if (this.toggleClass === 'fas fa-expand') {
            this.toggleClass = 'fas fa-compress';
        } else {
            this.toggleClass = 'fas fa-expand';
        }
    }


    /**
     * Filter the menu items based on the permissions of the currently logged in user.
     * @param routes The routes to filter. defaults to ROUTES imported.
     */
    filterMenu(routes: RouteInfo[]): RouteInfo[] {
        if (!this.profile) return routes;
        const permissions = this.profile.permissions;
        return routes.filter(menu => {
            if (menu.displayCallback) {
                if (!menu.displayCallback()) {
                    return null;
                }
            }
            if (menu.permission) {
                if (permissions.indexOf(menu.permission) > -1) {
                    return this.filterSubmenus(menu, permissions);
                }
                return null;
            } else {
                return this.filterSubmenus(menu, permissions);
            }
        });
    }

    filterSubmenus(routeInfo: RouteInfo, permissions: string[]): RouteInfo {
        if (routeInfo) {
            if (routeInfo.submenu && routeInfo.submenu.length > 0) {
                routeInfo.submenu = routeInfo.submenu.filter(menu => {
                    if (menu.displayCallback) {
                        if (!menu.displayCallback()) {
                            return null;
                        }
                    }
                    if (menu.permission) {
                        if (permissions.indexOf(menu.permission) > -1) {
                            return this.filterSubmenus(menu, permissions);
                        }
                        return null;
                    } else {
                        return this.filterSubmenus(menu, permissions);
                    }
                })
            }
        }
        return routeInfo;
    }

     /**
     * Retrieve the corporates available to be switched.
     */
     pullCorporates() {
        this.lookupService.switchableCorporates<BaseResponse<Lookup[]>>().subscribe(resp => {
            this.corporates = [{ id: '', text: '' }];
            if (resp.ok) {
                if (resp.body.code === '800.200.001') {
                    resp.body.data.forEach(element => {
                        this.corporates.push(
                            { id: element.id, text: element.name, additional: (<any>element).white_label });
                            if (element.id === this.auth.getActiveCorporateId()) {
                                this.selectedCorporateName = (<any>element).white_label;
                            }
                    });
                    if (this.profile) {
                        this.selectedCorporateId = this.auth.getActiveCorporateId();
                    }
                }
            }
        });
    }

    
    /**
     * Retrieve the corporate name based on the selected id
     */
    get getSelectedCorporate() {
        if (!this.corporates) {return ''; }
        const selectedCorporate = this.corporates.find(c => c.id === this.selectedCorporateId);
        if (selectedCorporate) {return selectedCorporate.text; }
        return '';
    }

    /**
     * Retrieves the selected white label for the corporate
     */
    public get getSelectedWhiteLabel(): string {
        if (!this.corporates) {return ''; }
        const selectedCorporate = this.corporates.find(c => c.id === this.selectedCorporateId);
        if (selectedCorporate) {
            if (selectedCorporate.additional) {return String(selectedCorporate.additional).toUpperCase();}
        }
        return '';
    }

    /**
     * Switch the current corporate to a new one.
     */
    switchCorporate() {
        if (this.selectedCorporateId && this.selectedCorporateId !== this.auth.getActiveCorporateId()) {
            this.swalService.render({
                type: 'question',
                title: 'Are you sure?',
                html: `Switch to <strong>${this.getSelectedCorporate || ''}</strong>`,
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(result => {
                if (result.value) {
                    this.auth.setActiveCorporateId(this.selectedCorporateId);
                    document.location.reload();
                } else {
                    this.selectedCorporateId = this.auth.getActiveCorporateId();
                }
            });
        }
    }
}
