/**
 * The menu representation we use to pull menu data
 */
export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  badge: string;
  badgeClass: string;
  permission?: string;
  isExternalLink: boolean;
  submenu?: RouteInfo[];
  displayCallback?: () => boolean;
}

/**
 * The module menu that needs to be loaded when into the top navbar.
 */
export class ModuleMenu {
  /**
   * The menu items to be loaded for this module.
   */
  menus: RouteInfo[];
  /**
   * Whether to display this menu or not.
   * If false, the navbar is entirely removed.
   */
  showMenu: boolean = false;

  /**
   * The default constructor to try and load the module menu.
   * @param showMenu Whether to display this menu or not.
   * @param menus The menus to display.
   */
  constructor(showMenu: boolean = false, menus?: RouteInfo[]) {
    this.showMenu = showMenu;
    this.menus = menus;
  }
}

/**
 * This is the standard response received from any API calls.
 * This is a generic type that allows us to define the type of data we expect by instantiating this class
 * with a type definition T
 */
export class BaseResponse<T> {
  /**
   * The processing results code.
   */
  code: string;
  /**
   * The response message.
   */
  message?: string;
  /**
   * Any data that may be attached to the response.
   */
  data?: T;
}

/**
 * This is the standard response received from most API calls.
 */
export class StandardResponse extends BaseResponse<any> {}

/**
 * Provides the retrieval of credit score data for the current logged in user.
 */
export class ScoreEngineData {
  has_metropol = false;
  has_transunion = false;
  has_fpl_credit_score = false;
  has_fpl_statements = false;
  constructor() {}
}

/**
 * Contains the user profile for the logged in user.
 */
export class Profile {
  id: string;
  user_id:string;
  token: string;
  expires_at: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  other_name: string;
  role_id: string;
  role_name: string;
  phone_number: string;
  state_id: string;
  state_name: string;
  language_code: string;
  branch_id: string;
  branch_name: string;
  organization_id: string;
  organization_code: string;
  organization_white_label: string;
  organization_name: string;
  last_activity: string;
  date_modified: string;
  date_created: string;
  permissions: string[];
  profile_image_id?: string;
  enable_core_banking: boolean;
  tncs_accepted: string;
  /**
   * The image friendly URL for profile pic.
   */
  profile_image_url?: string;
  mpesa: MPESAAccounts;
}

export class Login {
  username: string;
  password: string;
  remember: boolean;
  client_id: string;
}

export class Terms_and_Conditions {
  accept: boolean;
  data: string;
} 

export class LoginResponse {
  code: string;
  data: Profile;
}
export class MPESAAccounts {
  mpesa_c2b_account: string;
  mpesa_b2c_account: string;
}

export class Lookup {
  id: string;
  text: string;
  name: string;
}

export class Verification {
  security_code: string;
}

export class TokenData {
  token: string;
  expires_at: number;
}

export class TokenVerifyResponse {
  code: string;
  data: TokenData;
}

export class ForgotPassword {
  username: string;
  phone_number: string;
}

export class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  is_self: string = null;
}

export class ChartSeriesItem {
  name: string;
  value: string;
}

export class TransactionGraph {
  name: string;
  series: ChartSeriesItem[];
}

export class TransactionGraphResponse {
  data: TransactionGraph[];
}

export class TransactionData {
  total: number;
  success: number;
  failed: number;
  amount: string;
}

export class WidgetData {
  currency: string;
  controlAccount: string = "0.00";
  utilityBalance: string = "0.00";
  commissionBalance: string = "0.00";
  masterAgents: string = "0";
  masterAgentsPeriod: string = "0";
  agents: string = "0";
  agentsPeriod: string = "0";
  customers: string = "0";
  customersPeriod: string = "0";
  transactions: TransactionData;
  totalRevenue: string = "0";
  agentRevenue: string = "0";
  serviceProviderRevenue: string = "0";
  taxProviderRevenue: string = "0";
}

export class TransactionDetail {
  internal_reference: string;
  amount: string;
  transaction_type__simple_name: string;
  state__name: string;
  date_created: string;
}

export class TransactionDetailResponse {
  data: TransactionDetail[];
}

export class Corporate {
  name: string;
  whiteLabel: string;
  logoPath: string;

  constructor(
    name = "Finance Plan Sacco",
    whiteLabel = "FPS",
    logoPath = "assets/img/logo-mono.png"
  ) {
    this.logoPath = logoPath;
    this.name = name;
    this.whiteLabel = whiteLabel;
  }
}

/**
 * Events fired during file uploads.
 */
export class UploadEvent {
  source: XMLHttpRequest;
  event: ProgressEvent;
  /**
   * Track the completion so that we can extract the values from
   * @see source
   */
  complete = false;
}

export interface DTButton {
  /**
   * Action to take when the button is activated
   */
  action?: (e, dt, node, config) => {};
  /**
   * Collection of attribute key / values to set for a button
   */
  att?: any[];
  /**
   * Ensure that any requirements have been satisfied before initialising a button
   */
  available(dt, config): boolean;
}

/**
 * The config for the buttons
 */
export class ButtonConfig {
  excludeLastColumn = false;
  pdfOrientation: "portrait" | "landscape" = "portrait";
  title?: string;
  /**
   * Other buttons to be merged with the existing ones.
   */
  children?: any[];
}

/**
 * The events we are supporting for broadcast and listening on with our message bus strem.
 */
export enum MessageBusEventType {
  "dashload",
  "dataload",
  "reset",
  "login",
  "logout",
  "profileupdated",
  "moduleload",
  "moduleunload",
  "offline",
  "online",
}

/**
 * Events that we broadcast from our message bus.
 */
export class MessageBusEvent {
  /**
   * The type of event we are broadcasting.
   */
  type: MessageBusEventType;
  /**
   * The message we are sending.
   */
  message?: string;
  /**
   * Any data we want to attach to the event.
   */
  data?: any;
}

/**
 * Generic API request responses/errors
 */
export class GenericError {
  /**
   * The code identifying this error, ideally from an API call.
   */
  code: string;
  /**
   * The message associated with the respective error message.
   */
  message: string;

  /**
   * Default constructor for generic error message properties.
   * @param _code The error code for this error type.
   * @param _msg The message associated with this error.
   */
  constructor(_code: string, _msg: string) {
    this.code = _code;
    this.message = _msg;
  }

  /**
   * Helper override method to convert the object to string represention.
   * Conveniently, just returns the the error message.
   */
  toString() {
    return this.message;
  }
}

export class Institutions {
  id: string;
  text: string;
  institution_type_name: string;
  institution_type_id: string;
  status: string;
}

export class TemplateFields {
  id: string;
  name: string;
  simple_name: string;
  parent_id?: string;
  parent_name?: string;
  description: string;
  is_list = false;
  state_id: string;
  state_name: string;
  date_created: string;
  date_modified: string;
}

export class TemplateFieldsResponse {
  code: string;
  data: TemplateFields[];
}

export class ReportBuilder {
  id?: string;
  name: string;
  template: string;
  module: string;
  grouping: string;
  fields: string[];
  micro_fields?: {};
  institution_type?: string;
  institution?: string;
}

export interface Checkboxed {
  id: string;
  text: string;
  checked?: boolean;
}

export class Product {
  id: string;
  name: string;
  account_type_name: string;
  account_type_id: string;
  allow_interest_rate_overriding: boolean;
  allow_repayment_due_day_overriding: boolean;
  interest_rate: number;
  interest_type_name: string;
}

export class Destination {
  id: string;
  name: string;
}

export class Collateral {
  id: string;
  name: string;
  guarantee_value_percentage: number;
}

export class PriorityNotification {
  can_close: boolean;
  show_notification: boolean;
}
