import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-details-render',
  templateUrl: './details-render.component.html',
  styleUrls: ['./details-render.component.scss']
})
export class DetailsRenderComponent implements OnInit {
  /**
   * The title to place in the details control.
   */
  @Input() title: string;
  /**
   * The details content. Can be a plain string or a list of strings to be concatenated!
   */
  @Input() content: string | Array<string>;

  /**
   * Whether this control is in a form. This requires special formatting to correspond to the inputs.
   */
  @Input() inForm = false;
  constructor() { }

  ngOnInit() {
  }

  /**
   * Clean the content to ensure that it's either a string or an array validly.
   */
  public get CleanContent(): string {
    if (
      typeof this.content === 'string' || typeof this.content === 'number' || 
      typeof this.content === 'boolean') {
      return this.content;
    }
    else if (Array.isArray(this.content)) {
      let fullContent = '';
      try {
        this.content.forEach(element => {
          if (element) fullContent += element + ' ';
        });
      } catch (ex) { }
      return fullContent;
    }
    else if (!this.content) {
      return '-';
    } else {
      return this.content;
    }
  }

  /**
   * Retrieves the form class handler.
   */
  public get formClass() : string {
    if (this.inForm) {
      return 'has-form';
    }
    return '';
  }
  
}
