import { Injectable } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { ApproveSavingWithdrawalResponse, RejectSavingWithdrawalResponse, AccountsResponse } from './savings.model';
import { StandardResponse } from '../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class SavingsService {

constructor(private sharedService: SharedService) { }

/**
 * GetAllSavings
 * Retrieves all saving records from core.
 */

  public getAllSavings<T>(data: any) {
    return this.sharedService.postWrapper<T>('dt/all_savings/', data);
  }

  public getAllDepositAccounts<T>(data: any) {
    return this.sharedService.postWrapper<T>('dt/deposits_accounts/', data);
  }

  public getAllSharesAccounts<T>(data: any) {
    return this.sharedService.postWrapper<T>('dt/shares_accounts/', data);
  }

  public getSavingDetails<T>(identifier: string){
    return this.sharedService.postWrapper<T>('saving_details/', {saving_id: identifier});
  }

  public getSavingEntries<T>(data: any){
    return this.sharedService.postWrapper<T>('dt/saving_entries/', data);
  }

  public getSavingsPendingApproval<T>(data: any){
    return this.sharedService.postWrapper<T>('dt/savings_pending_approval/', data);
  }

  public approveWithdrawalSaving(id: string){
    return this.sharedService.postWrapper<ApproveSavingWithdrawalResponse>('approve_withdraw_savings/', {saving_id: id});
  }

  public rejectWithdrawalSaving(id: string){
    return this.sharedService.postWrapper<RejectSavingWithdrawalResponse>('reject_withdraw_savings/', {saving_id: id});
  }

  public lockSaving(id: string) {
    return this.sharedService.postWrapper<RejectSavingWithdrawalResponse>('savings_lock/', {saving_id: id});
  }

  public unlockSaving(id: string) {
    return this.sharedService.postWrapper<RejectSavingWithdrawalResponse>('savings_unlock/', {saving_id: id});
  }

  public searchAccounts<T>(data: any) {
    return this.sharedService.postWrapper<AccountsResponse>('search_account_by_text/', data);
  }

  public openSharesAccount<T>(data: any) {
    return this.sharedService.postWrapper<AccountsResponse>('open_shares_account/', data);
  }

  public openDepositAccount<T>(data: any) {
    return this.sharedService.postWrapper<AccountsResponse>('open_deposit_account/', data);
  }

  public depositIntoDepositAccount<T>(data: any) {
    return this.sharedService.postWrapper<StandardResponse>('save_to_deposit_account/', data);
  }

  public depositIntoShareAccount<T>(data: any) {
    return this.sharedService.postWrapper<StandardResponse>('save_to_shares_account/', data);
  }

}
