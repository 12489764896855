import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { ToastrService } from 'ngx-toastr';

// Models
import { Login, LoginResponse, TokenVerifyResponse, Profile, Verification, StandardResponse, ForgotPassword, Terms_and_Conditions } from '../models';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
    server_url = environment.serverUrl
    constructor(private router: Router, private http: HttpClient, private toast: ToastrService) { }

    /**
     * Attempts to login the user with the credentials in the login model.
     * @param model The login model to authenticate the user with.
     * @returns A promise of boolean if we attempted to navigate to the dashboard or a string representing
     * the error message if we sent the request to the server but couldn't authenticate the user.
     */
    async login(model: Login, next: string = null): Promise<boolean | string> {
        model.client_id = environment.clientId;
        const resp = await this.http.post<LoginResponse>(
            `${this.server_url}api/authenticate_user/`, model, { observe: 'response' }).toPromise();
        if (resp.ok) {
            const loginResponse = resp.body;
            console.log(loginResponse)
            if (loginResponse.code === '800.200.001') { // Handle success
                sessionStorage.setItem('token', loginResponse.data.token);
                sessionStorage.setItem('token_expires_at', loginResponse.data.expires_at);
                // await this.getUserData();  // Retrieve the profile data right now
                return this.router.navigate(['auth', 'verification'], { queryParams: { next: next } });
            } else {
                return Promise.resolve<boolean>(false); // Fail by default
            }
        } else {
            return Promise.resolve<boolean>(false); // Fail by default
        }
    }

    async tncs(model: Terms_and_Conditions, next: string = null): Promise<boolean | string> {
        model['data'] = sessionStorage.getItem('user_id').replace('"', '').replace('"', '')
        console.log(model['data'])
        if (model['accept'] === true) {
            model['accepted'] = "ACCEPTED";
        }
        return await this.http.post<LoginResponse>(
            `${this.server_url}api/accept_terms_and_conditions/`, model, { observe: 'response' }).toPromise().then(async resp => {
            if (resp.ok) {
                const acceptedResponse = resp.body;
                console.log(acceptedResponse)
                if (acceptedResponse.code === '100.000.000') { // Handle success
                    var profile =  await this.getUserData()
                    console.log(profile)
                    if (profile) {
                        if (next) {
                            try {
                                const n = await this.router.navigateByUrl(decodeURI(next));
                                if (!n) {
                                    return this.router.navigate(['panel']); // 'dashboard'
                                } else {
                                    return n;
                                }
                            } catch (error) {
                                return this.router.navigate(['panel']); // 'dashboard'
                            }
                        } else {
                            return this.router.navigate(['panel']); // 'dashboard'
                        }
                    } else {
                        return Promise.resolve<boolean>(false); // Something happened
                    }
                } else {
                    return Promise.resolve<boolean>(false); // Fail by default
                }
            } else {
                return Promise.resolve<boolean>(false); // Fail by default
            }
            });
    }

    /**
     * Verifies that the current session token is valid
     */
    async verifyToken(): Promise<boolean> {
        const resp = await this.http.post<TokenVerifyResponse>(
            `${this.server_url}api/verify_user_token/`,
            { token: this.getToken() }, { observe: 'response' })
            .toPromise();
        if (resp.ok) {
            if (resp.body.code === '800.200.001') { // Handle success
                sessionStorage.setItem('token_expires_at', resp.body.data.expires_at.toString());
                return Promise.resolve<boolean>(true); // Token is very valid
            } else {
                return Promise.resolve<boolean>(false); // Fail by default
            }
        } else {
            return Promise.resolve<boolean>(false); // Fail by default
        }
    }


    

    /**
     * Verifies that the current session token is valid
     */
    async verifySecurityCode(model: Verification, next: string = null): Promise<boolean> {
        return this.http.post<LoginResponse>(
            `${this.server_url}api/authenticate_user_verify/`,
            { ...model,  otp: model.security_code },
            { observe: 'response' }).toPromise().then(async resp => {
                if (resp.ok) {
                    if (resp.body.code === '800.200.001') {  // Handle success
                        const profile = await this.getUserData();
                        console.log(profile)
                        if (profile) {
                            if (next) {
                                try {
                                    const n = await this.router.navigateByUrl(decodeURI(next));
                                    if (!n) {
                                        return this.router.navigate(['panel']); // 'dashboard'
                                    } else {
                                        return n;
                                    }
                                } catch (error) {
                                    return this.router.navigate(['panel']); // 'dashboard'
                                }
                            } else {
                                return this.router.navigate(['panel']); // 'dashboard'
                            }
                        } else {
                            return Promise.resolve<boolean>(false); // Something happened
                        }
                    } else {
                        console.log(resp)
                        return Promise.resolve<boolean>(false);  // Fail by default
                    }
                } else {
                    return Promise.resolve<boolean>(false);  // Fail by default
                }
            });
    }

    /**
     * Logs out the user by resetting the token.
     */
    async logout() {
        try {
            const t = this.getToken();
            const resp = this.http.post<LoginResponse>(
                `${this.server_url}api/logout_user/`, { token: t}, { observe: 'response' })
                .toPromise().then(_ => true).catch(_ => true);
            sessionStorage.clear();
            clearInterval(window.tokenChecker);
            return resp;
        } catch (error) { }
    }

    /**
     * Retrieves the token from the session.
     */
    getToken() {
        return sessionStorage.getItem('token');
    }

    /**
     * Retrieves the expiration time in Unix seconds for the token.
     * If not found, null is returned.
     */
    getTokenExpiration(): number {
        const expirationTime = sessionStorage.getItem('token_expires_at');
        if (expirationTime) {
            try {
                // tslint:disable-next-line:radix
                return parseInt(expirationTime);
            } catch (error) {

            }
        }
        return null;
    }

   
    /**
     * Checks if the user is authenticated and the authentication token is valid.
     * @returns True if validly authenticated, False otherwise.
     */
    async isAuthenticated(): Promise<boolean> {
        if (this.getToken()) { return Promise.resolve<boolean>(true); } else {
            this.toast.error('You are not authorized to view the requested page!', 'Unauthorized');
            this.router.navigate(['auth']).then(_ => true).catch(_ => true);
            return Promise.resolve<boolean>(false);
        }
    }

    /**
     * Retrieve the details of the user who is logged in from the server.
     */
    async getUserData(): Promise<Profile> {
        var profile = this.http.post<LoginResponse>(
            `${this.server_url}api/get_user_profile/`,
            {token: this.getToken()}, { observe: 'response' }).toPromise().then(
                resp => {
                    if (resp.ok && resp.body.code === '800.200.001') {
                        sessionStorage.setItem('user_id', JSON.stringify(resp.body.data.user_id));
                        if (resp.body.data.tncs_accepted === "Accepted") {
                            sessionStorage.setItem('profile', JSON.stringify(resp.body.data));
                        return resp.body.data;
                    }else{
                        console.log("PLease accept Terms and conditions first",resp.body.data)
                        this.router.navigate(['auth', 'tncs']);
                    }
                }else{
                    console.log(resp)
                }
                return null;
                }).catch(_ => null);
        return profile
    }

    /**
     * Retrieves the profile data that has been retrieved from the server.
     */
    retrieveLocalUserData(): Profile {
        const profile = sessionStorage.getItem('profile');
        if (!profile) { return null; }
        const profileModel = JSON.parse(profile) as Profile;
        return profileModel;
    }

    /**
     * Updates the profile data locally for the user.
     */
    updateLocalUserData(data: Profile) {
        sessionStorage.setItem('profile', JSON.stringify(data));
    }

    /**
     * Retrieves the permissions for the user
     */
    retrieveUserPermissions(): string[] {
        const profile = this.retrieveLocalUserData();
        if (profile) {
            return profile.permissions;
        } else {
            return null;
        }
    }

    /**
     * Checks if the user has the permission provided.
     * @param permission The permission to check if the user has.
     *
     * @returns True if the user has the permission, False otherwise.
     */
    hasPermission(permission: string): boolean {
        const permissions = this.retrieveUserPermissions();
        if (permissions) {
            return permissions.indexOf(permission) > -1;
        }
        return false;
    }

    /**
     * Forgot password method.
     */
    forgotPassword(model: ForgotPassword) {
        return this.http
            .post<StandardResponse>(`${this.server_url}api/user/reset_password/`, model, { observe: 'response' });
    }

    /**
     * Retrieves the set active corporate in the local storage.
     */
    getActiveCorporateId() {
        return localStorage.getItem('session_key');
    }

    /**
     * Sets the active corporate for the session.
     * @param corporate_id The corporate id to set.
     * @param override If not override, we won't set if the key already exists.
     */
    setActiveCorporateId(corporate_id: string, override = true): void {
        if (override) {
            localStorage.setItem('session_key', corporate_id);
        } else {
            if (!localStorage.getItem('session_key')) {  // Set only if it doesn't exist
                localStorage.setItem('session_key', corporate_id);
            }
        }
    }
}
