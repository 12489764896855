// Core
import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

// Modules
import { DashboardModule } from "./dashboard/dashboard.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { SharedModule } from "./shared/shared.module";
import { AppRoutes } from "./app.routing";

// Components
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

// Services
import { httpInterceptorProviders } from "./shared/providers/http-provider";
import { ErrorHandlerProviderService } from "./shared/providers/error-handler-provider.service";
import { ngbDateFormatterProviders } from "./shared/providers/ngb-date-formatter";
import { ngbDateAdapterProviders } from "./shared/providers/ngb-custom-date-adapter";

// Others
import { environment } from "../environments/environment";

import * as $ from "jquery";
import { DatePipe } from "@angular/common";

@NgModule({
  declarations: [AppComponent, ContentLayoutComponent, FullLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutes,
    HttpClientModule,
    SharedModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerProviderService,
    },
    ngbDateFormatterProviders,
    ngbDateAdapterProviders,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
