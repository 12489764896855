import {
  HTTP_INTERCEPTORS,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpCancellableService } from './http-cancellable.service';
import { takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private cancellableHttp: HttpCancellableService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let newBody = null;
    // if (req.body) {
    //   newBody = { ...req.body, token: this.auth.getToken(), client_id: environment.clientId };
    // } else {
    //   newBody = { token: this.auth.getToken(), client_id: environment.clientId };
    // }
    if (req.body) {
      newBody = {
        ...req.body, token: this.auth.getToken(), client_id: environment.clientId,
        corporate_id: this.auth.getActiveCorporateId()
      };
    } else {
      newBody = {
        token: this.auth.getToken(), client_id: environment.clientId,
        corporate_id: this.auth.getActiveCorporateId()
      };
    }

    let params = req.params;

    if (req.method.toLocaleUpperCase() === 'GET') {
      params = params || new HttpParams();
      params = params.set('token', this.auth.getToken()).set('client_id', environment.clientId)
        .set('corporate_id', this.auth.getActiveCorporateId());
      // params = params.set('token', this.auth.getToken()).set('client_id', environment.clientId);
    }

    const authReq = req.clone({
      //   headers: req.headers.set('Authorization', 'authToken'),  // Do we send the token in the header?
      body: newBody,
      params: params
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq)
      .pipe(takeUntil(this.cancellableHttp.onCancelPendingRequests()))
      .pipe(tap(event => {
        if (event instanceof HttpResponse) {
          try {
            if (event.body.code === '401') {
              setTimeout(() => {
                if (!this.router.url.startsWith('/auth/')) {
                  this.router.navigate(['auth', 'login'], { queryParams: { next: this.router.url } });
                }
              }, 2500);
            }
          } catch (error) { }
        }
      }, _error => { }));
  }
}

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
