import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Router } from '@angular/router';
import { Profile, MessageBusEventType, BaseResponse, Lookup } from '../models';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { MessageBusService } from '../services/message-bus.service';
import { FileManagerService } from '../services/file-manager.service';
import { SafeUrl } from '@angular/platform-browser';
import { Select2OptionData } from '../components/select2/select2.models';
import { LookupService } from '../services/lookup.service';
import { SwalService } from '../services/swal.service';

@Component({
    selector: 'ox-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    currentLang = 'en';
    toggleClass = 'ft-maximize';
    public isCollapsed = true;
    isLoaded = false;
    loadingError = false;
    profile: Profile;

    public corporates: Array<Select2OptionData>;
    public selectedCorporateId = '';
    public selectedCorporateName = '';
    /**
     * The user profile image.
     */
    profileImageUrl: string | SafeUrl = `assets/img/portrait/avatars/avatar100.jpg`;
    appName = environment.appName;
    appIcon = environment.appIcon;

    @Output() updateApp: EventEmitter<any> = new EventEmitter();

    constructor(
        public router: Router,
        private auth: AuthService,
        public swUpdates: SwUpdate, private swalService: SwalService,
        private lookupService: LookupService,
        private messageBus: MessageBusService,
        private fileManager: FileManagerService) { }

    ngOnInit() {
        this.isLoaded = true;
        this.profile = this.auth.retrieveLocalUserData();
        this.generateProfileImagePreview();
        this.messageBus.on(MessageBusEventType.profileupdated).subscribe(_ => {
            // Update the profile data again!
            this.profile = this.auth.retrieveLocalUserData();
            // Reload the profile image just in case
            this.generateProfileImagePreview();
        });
        this.selectedCorporateName = this.profile.organization_white_label;
        if (this.auth.hasPermission('can_switch_corporate')) {
            this.pullCorporates();
        }
    }

    /**
     * Retrieve the corporates available to be switched.
     */
    pullCorporates() {
        this.lookupService.switchableCorporates<BaseResponse<Lookup[]>>().subscribe(resp => {
            this.corporates = [{ id: '', text: '' }];
            if (resp.ok) {
                if (resp.body.code === '800.200.001') {
                    resp.body.data.forEach(element => {
                        this.corporates.push(
                            { id: element.id, text: element.name, additional: (<any>element).white_label });
                            if (element.id === this.auth.getActiveCorporateId()) {
                                this.selectedCorporateName = (<any>element).white_label;
                            }
                    });
                    if (this.profile) {
                        this.selectedCorporateId = this.auth.getActiveCorporateId();
                    }
                }
            }
        });
    }

    // ToggleClass() {
    //     if (this.toggleClass === 'ft-maximize') {
    //         this.toggleClass = 'ft-minimize';
    //     } else {
    //         this.toggleClass = 'ft-maximize';
    //     }
    // }

    onUpdateApp() {
        this.updateApp.emit(true);
    }

    loadSettings() {
        this.router.navigate(['/', 'panel', 'settings']);
    }

    /**
     * Generates a preview of the profile image.
     */
    generateProfileImagePreview() {
        if (this.profile) {
            if (this.profile.profile_image_id) {
                this.fileManager
                .downloadWrapper('file_manager/download/', {id: this.profile.profile_image_id}).then(resp => {
                    if (resp.ok) {
                        this.profileImageUrl = this.fileManager.previewFile(resp);
                    }
                });
            }
        }
    }

    /**
     * Retrieve the corporate name based on the selected id
     */
    get getSelectedCorporate() {
        if (!this.corporates) {return ''; }
        const selectedCorporate = this.corporates.find(c => c.id === this.selectedCorporateId);
        if (selectedCorporate) {return selectedCorporate.text; }
        return '';
    }

    /**
     * Retrieves the selected white label for the corporate
     */
    public get getSelectedWhiteLabel(): string {
        if (!this.corporates) {return ''; }
        const selectedCorporate = this.corporates.find(c => c.id === this.selectedCorporateId);
        if (selectedCorporate) {
            if (selectedCorporate.additional) {return String(selectedCorporate.additional).toUpperCase();}
        }
        return '';
    }

    /**
     * Switch the current corporate to a new one.
     */
    switchCorporate() {
        if (this.selectedCorporateId && this.selectedCorporateId !== this.auth.getActiveCorporateId()) {
            this.swalService.render({
                type: 'question',
                title: 'Are you sure?',
                html: `Switch to <strong>${this.getSelectedCorporate || ''}</strong>`,
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(result => {
                if (result.value) {
                    this.auth.setActiveCorporateId(this.selectedCorporateId);
                    document.location.reload();
                } else {
                    this.selectedCorporateId = this.auth.getActiveCorporateId();
                }
            });
        }
    }
}
